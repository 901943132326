// import React, { useState } from "react";
// import "./SetPassword.css";
// import Button from "../../../utilities/button/Button";
// import logo from "../../../assets/Logo3.jpg";
// import { useLocation, useNavigate } from "react-router-dom";
// import arrow_left from "../../../assets/arrow-left-line.png";
// import { oneIdRegister } from "../../../api/auth_api";
// import toast from "react-hot-toast";

// const SetPassword = () => {
//   const location = useLocation();
//   const { email } = location.state || {};
//   const navigate = useNavigate();
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [error, setError] = useState('');
//   const [passwordSetLoading,setPasswordSetLoading] = useState(false)

//   const handleRegister = async () => {
//     if (password !== confirmPassword) {
//       setError('Passwords do not match!');
//       return;
//     }
//     if (confirmPassword === "") {
//       setError("Password fields can't be empty!");
//       return;
//     }
//     setPasswordSetLoading(true)
//     const registerPayload={
//       email:email,
//       password:confirmPassword
//     }
//     const registerRes = await oneIdRegister(registerPayload)
//     if(registerRes.code === 100){
//       setPasswordSetLoading(false)
//       toast.success("Password set successfully")
//       navigate(`/login`);
//     }
//     else{
//       setPasswordSetLoading(false)
//       toast.error(registerRes.message);
//     }
//   }
//   const handleClick = () => {
//     alert("Button clicked!");
//   };
//   return (
//     <div className="d-flex justify-content-center align-items-center vh-100">
//       <div className="main-div">
//       <div className="d-flex justify-content-start align-items-center mb-4 vw-100 arrow-left">
//           <img
//             src={arrow_left}
//             alt=""
//             width={24}
//             onClick={() => {
//               navigate(-1);
//             }}
//           />
//         </div>
//         <div className="d-flex justify-content-center align-items-center mb-4">
//           <img src={logo} alt="" width={140}/>
//         </div>
//         <div className="d-flex justify-content-center align-items-center set-password-text">
//         Set your password
//         </div>
//         <div className="description-text">
//         Simple, secure one‑click payments.
//         </div>
//         <div className="password-label-div-text">Set new password</div>
//         <div className="password-div-type ">
//           <input
//             type="password"
//             name=""
//             id=""
//             className="password-div-type-input"
//             placeholder="Set new password"
//             value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           />
//         </div>
//         <div className="password-label-div-text">Confirm password</div>
//         <div className="password-div-type ">
//           <input
//             type="password"
//             name=""
//             id=""
//             className="password-div-type-input"
//             placeholder="Confirm password"
//             value={confirmPassword}
//             onChange={(e) => setConfirmPassword(e.target.value)}
//           />
//         </div>
//         {error && <div style={{ color: 'red' }}>{error}</div>}
//           <Button
//             label="Save password"
//             type="submit"
//             onClick={handleRegister}
//             className="sign-in-button"
//             loading={passwordSetLoading}
//           />
//       </div>
//     </div>
//   );
// };

// export default SetPassword;
import React, { useState, useEffect } from "react";
import "./SetPassword.css";
import Button from "../../../utilities/button/Button";
import logo from "../../../assets/Logo3.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import arrow_left from "../../../assets/arrow-left-line.png";
import { oneIdRegister } from "../../../services/auth_api";
import toast from "react-hot-toast";
import eye_icon from "../../../assets/eye-line.png"

const SetPassword = () => {
  const location = useLocation();
  const { email } = location.state || {};
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordSetLoading, setPasswordSetLoading] = useState(false);
  const [passwordStrengthError, setPasswordStrengthError] = useState('');
  const [isNewPasswordSee,setIsNewPasswordSee] = useState(false)
  const [isComfirmPasswordSee,setIsComfirmedPasswordSee] = useState(false)

  const isPasswordStrong = (password: any) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^])[A-Za-z\d@$!%*?&^]{8,}$/;
    return passwordRegex.test(password);
  };
  

  useEffect(() => {
    if (password && !isPasswordStrong(password)) {
      setPasswordStrengthError('Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.');
    } else {
      setPasswordStrengthError('');
    }
  }, [password]);
  useEffect(() => {
    
    if(confirmPassword === ""){
      setError("")
    }
  }, [confirmPassword]);

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match!');
      return;
    }
    if (password === "" || confirmPassword === "") {
      setError("Password fields can't be empty!");
      return;
    }
    if (password !== "" && confirmPassword !== "" && !isPasswordStrong(password)) {
      setError('Password is not strong enough!');
      return;
    }

    setPasswordSetLoading(true);
    const registerPayload = {
      email: email,
      password: confirmPassword
    };
    const registerRes = await oneIdRegister(registerPayload);
    if (registerRes.code === 100) {
      setPasswordSetLoading(false);
      toast.success("Password set successfully");
      setTimeout(async () => {
        navigate(`/login`);
      }, 1000);
      
    } else {
      setPasswordSetLoading(false);
      toast.error(registerRes.message);
    }
  };

  const handleClick = () => {
    alert("Button clicked!");
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="main-div">
        <div className="d-flex justify-content-start align-items-center mb-4 vw-100 arrow-left">
          <img
            src={arrow_left}
            alt=""
            width={24}
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <img src={logo} alt="" width={140} />
        </div>
        <div className="d-flex justify-content-center align-items-center set-password-text">
          Set your password
        </div>
        <div className="description-text">
          Simple, secure one‑click payments.
        </div>
        <div className="password-label-div-text">Set new password</div>
        <div className="password-div-type">
          <input
            type={isNewPasswordSee ? "text":"password"}
            className="password-div-type-input"
            placeholder="Set new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span><img src={eye_icon} alt="" width={17} className="mr-2" style={{cursor:"pointer"}} onClick={()=>{
            setIsNewPasswordSee(!isNewPasswordSee)
          }}/></span>
        </div>
        {passwordStrengthError && <div className="errors">{passwordStrengthError}</div>}
        <div className="password-label-div-text">Confirm password</div>
        <div className="password-div-type">
          <input
            type={isComfirmPasswordSee ? "text":"password"}
            className="password-div-type-input"
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <span><img src={eye_icon} alt="" width={17} className="mr-2" style={{cursor:"pointer"}} onClick={()=>{
            setIsComfirmedPasswordSee(!isComfirmPasswordSee)
          }}/></span>
        </div>
        {error && <div className="errors">{error}</div>}
        <Button
          label="Save password"
          type="submit"
          onClick={handleRegister}
          className="sign-in-button"
          loading={passwordSetLoading}
        />
      </div>
    </div>
  );
};

export default SetPassword;
