import React from 'react';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';
import "./Button.css"

interface ButtonProps {
  label: string;
  onClick: () => void;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  loading?:boolean
}

const Button: React.FC<ButtonProps> = ({ label, onClick, type = 'button', className = '',loading }) => {
  return (
    <button type={type} onClick={onClick} className={`btn ${className}`} disabled={loading}>
      {!loading ?label:<Loader className="spinner" />}
    </button>
  );
};

export default Button;
