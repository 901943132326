import React, { useState, useEffect, useCallback } from "react";
import "./CreateAccount.css";
import axios from "axios";
import debounce from "lodash.debounce";
import Button from "../../../utilities/button/Button";
import logo from "../../../assets/Logo3.jpg";

import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { oneIdOTPSend, verifyEmailExist } from "../../../services/auth_api";

const CreateAccount = () => {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)

  const [email, setEmail] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginBtnEnable, setLoginBtnEnable] = useState(false);
  const [registerBtnEnable, setRegisterBtnEnable] = useState(false);
  const [otpSendLoading, setOtpSendLoading] = useState(false);
  const [error, setError] = useState("");
  const [isVerifiedEmail,setIsverifiedEmail]= useState(false);
  const [isNotVerifiedEmail,setIsNotverifiedEmail]= useState(false);

  // Function to check email format using regex
  const isEmailValidFormat = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  // // Debounced function to call the API
  // const validateEmail = debounce(async (email) => {
  //   setLoading(true);

  //   const response = await verifyEmailExist(email);
  //   console.log(response);
  //   if (response.code === 100) {
  //     if (response.data.registered) {
  //       setLoginBtnEnable(true);
  //       setRegisterBtnEnable(false);
  //       setIsverifiedEmail(true)
  //     } else {
  //       setLoginBtnEnable(false);
  //       setRegisterBtnEnable(true);
  //       //setIsNotverifiedEmail(true)
  //     }
  //     setIsRegistered(response.data.registered);
  //     setLoading(false);
  //   } else {
  //     setIsRegistered(false);
  //     setLoading(false);
  //   }
  // }, 500); // 500ms debounce
  const validateEmail = useCallback(
    debounce(async (email) => {
      setLoading(true);
      setLoginBtnEnable(false);
      setRegisterBtnEnable(false);
      try {
        const response = await verifyEmailExist(email);
        console.log(response);

        if (response.code === 100) {
          if (response.data.registered) {
            setLoginBtnEnable(true);
            setRegisterBtnEnable(false);
            setIsverifiedEmail(true);
            setIsNotverifiedEmail(false);
          } else {
            setLoginBtnEnable(false);
            setRegisterBtnEnable(true);
            setIsverifiedEmail(false);
            //setIsNotverifiedEmail(true);
          }
          setIsRegistered(response.data.registered);
        } else {
          setIsRegistered(false);
        }
      } catch (error) {
        console.error("Error validating email:", error);
        setError("An error occurred while validating the email.");
      } finally {
        setLoading(false);
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (email && isEmailValidFormat(email)) {
      setError('');
      validateEmail(email);
    } else if (email) {
      setError('Invalid email format');
      setIsRegistered(false);
    } else {
      setError('');
      setIsRegistered(false);
    }
  }, [email]);

  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };
 
  const goToRegister = async () => {
    setOtpSendLoading(true);
    const otpSendPayload = {
      email: email,
    };
    const otpSendRes = await oneIdOTPSend(otpSendPayload);
    if (otpSendRes.code === 100) {
      console.log("OTP send res: ", otpSendRes);
      toast.success(otpSendRes.message);
      setOtpSendLoading(false);
      navigate(`verify-otp`, { state: { email: email,isRegistered: isRegistered,route_fp:false, code:queryParameters.get("c") } });
    } else {
      setOtpSendLoading(false);
      toast.error("OTP sending failed");
    }
  };
  const goToLogin = async() => {
    setOtpSendLoading(true);
    const otpSendPayload = {
      email: email,
    };
    const otpSendRes = await oneIdOTPSend(otpSendPayload);
    if (otpSendRes.code === 100) {
      console.log("OTP send res: ", otpSendRes);
      toast.success(otpSendRes.message);
      setOtpSendLoading(false);
      navigate(`verify-otp`, { state: { email: email,isRegistered: isRegistered,route_fp:false, code:queryParameters.get("c")} });
    } else {
      setOtpSendLoading(false);
      toast.error("OTP sending failed");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="main-div">
        <div className="d-flex justify-content-center align-items-center mb-4">
          <img src={logo} alt="" width={140} />
        </div>
        <div className="d-flex justify-content-center align-items-center create-account-text">
        Welcome to One ID
        </div>
        <div className="description-text">
        Simple, secure one‑click payments
        </div>
        {/* <div className="description-text">
        Log in or sign up to get started.
        </div> */}
        <div className="email-label-div-text">Log in or sign up to get started.</div>
        {/* <div className="email-div-type "> */}
          <input
            type="email"
            name=""
            id=""
            className={`email-div-type-input ${isVerifiedEmail ? 'verified-background' : ''} ${isNotVerifiedEmail ? 'unverified-background' : ''}`}
            placeholder="Write your email here"
            value={email}
            onChange={handleChange}
          />
        {/* </div> */}
        {/* {loading && <p>Validating...</p>} */}
        {error && <p className="email-invalid-text">{error}</p>}
        {registerBtnEnable && (
          <Button
            label="Sign up"
            type="submit"
            onClick={goToRegister}
            className="sign-in-button"
            loading={otpSendLoading}
          />
        )}
        {loginBtnEnable && (
          <Button
            label="Log in"
            type="submit"
            onClick={goToLogin}
            className="sign-in-button"
            loading={otpSendLoading}
          />
        )}
        {loading&& <div className="loader">
            <div className="bubble"></div>
            <div className="bubble"></div>
            <div className="bubble"></div>
          </div>}
        <div className="already-have-text">
          Already have an account?{" "}
          <span
            onClick={() => {
              navigate(`/login`);
            }}
            className="login-text"
          >
            Login
          </span>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
