import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LeftOffCanvas.css";
import group1 from "../../assets/Group1.png";

interface LeftOffCanvasProps {
  onClose: () => void;
  children: React.ReactNode;
  heading:String
}

const LeftOffCanvas: React.FC<LeftOffCanvasProps> = ({ onClose,children,heading }) => {
  const [isOpenView, setIsOpenView] = useState(true);
  const navigate = useNavigate();

  const handleOpen = () => {
    setIsOpenView(true);
  };

  const handleClose = () => {
    setIsOpenView(false);
    // navigate(-1);
    onClose();
  };

  return (
    <>
      {isOpenView && (
        <div className="off-canvas-overlay-view"></div>
      )}
      
      <div className={`off-canvas-view ${isOpenView ? "open-view" : ""}`}>
        <div className="off-canvas-content-view">
          {/* <button className="close-button" onClick={handleClose}>
            Close
          </button> */}
          <div className="row">
            <div className="col-12 d-flex justify-content-between">
              <div className="tittle">{heading}</div>
              <img
                className="pe-2 close-icon"
                src={group1}
                onClick={handleClose}
              />
            </div>
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default LeftOffCanvas;