import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import CreateAccount from "./components/auth/create-account/CreateAccount";
import VerifyOTP from "./components/auth/verify-otp/VerifyOTP";
import Footer from "./utilities/footer/Footer";
import SetPassword from "./components/auth/set-password/SetPassword";
import TransactionHistory from "./components/payments/transaction-history/TransactionHistory";
import Profile from "./components/payments/profile/Profile";
import Login from "./components/auth/login/Login";
import AuthGuard from "./services/auth_guard";
import { Toaster } from "react-hot-toast";
import FoegotPassword from "./components/auth/forgot-password/FoegotPassword";
import ResetPassword from "./components/auth/reset-password/ResetPassword";
import MoneyTransfer from "./components/payments/money-transfer/set-details/money-transfer";
import FavouriteTransaction from "./components/payments/money-transfer/favourite-transaction/favourite-transaction";
import AddTransactionDetails from "./components/payments/money-transfer/add-transaction-details/add-transaction-details";

import { ContextProvider } from "./services/context_provider";
import InviteFriends from "./components/invite-friend/friend-invite/InviteFriends";
function App() {
  return (
    <Router>
      <ContextProvider>
        <AppContent />
      </ContextProvider>
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  const renderFooter = () => {
    const pathsWithFooter = ["/", "/verify-otp", "/set-password"];
    return pathsWithFooter.includes(location.pathname);
  };

  return (
    <div className="App d-flex justify-content-center align-items-center">
      <Routes>
        <Route path="/" element={<CreateAccount />} />
        <Route path="send-money" element={<MoneyTransfer />} />
        <Route path="set-favourite" element={<FavouriteTransaction />} />
        <Route
          path="add-transaction-details"
          element={<AddTransactionDetails />}
        />
        {/* <Route path="/" element={<TransactionHistory />} /> */}
        {/* <Route path="/" element={<Profile />}/> */}
        <Route path="login" element={<Login />} />
        <Route path="verify-otp" element={<VerifyOTP />} />
        <Route path="set-password" element={<SetPassword />} />
        <Route path="forgot-password" element={<FoegotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        {/* <Route path="transaction-history" element={<TransactionHistory />} />
        <Route path="profile" element={<Profile />} /> */}
        <Route
          path="transaction-history"
          element={
            <AuthGuard>
              <TransactionHistory />
            </AuthGuard>
          }
        />
        
        <Route
          path="/profile"
          element={
            <AuthGuard>
              <Profile />
            </AuthGuard>
          }
        />
        <Route
          path="invite-friend"
          element={
            <AuthGuard>
              <InviteFriends />
            </AuthGuard>
          }
        />
      </Routes>
      {renderFooter() && <Footer />}
      <Toaster />
    </div>
  );
}

export default App;
