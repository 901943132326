import React, { useEffect, useState } from "react";
import "./AddFavouriteDialog.css";
import success_icon from "../../assets/Featured icon.png";
import group1 from "../../assets/Group1.png";
import { addPayee } from "../../services/money_transfer";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

interface AddFavouriteDialogProps {
  favuriteData:any;
  onClose: () => void;
  isButtonLoading: Boolean;
}

const AddFavouriteDialog: React.FC<AddFavouriteDialogProps> = ({
  onClose,
  isButtonLoading,
  favuriteData
}) => {
  const navigate = useNavigate();
  const [isOpenView, setIsOpenView] = useState(false);
  const [payeeName,setPayeeName] = useState("");
  const [payeeAddLoading,setPayeeAddLoading] = useState(false)

  useEffect(() => {
    // Trigger the transition effect
    setTimeout(() => setIsOpenView(true), 0);
  }, []);

  const handleClose = () => {
    setIsOpenView(false);
    setTimeout(onClose, 500); // Match the transition duration
    navigate(`/transaction-history`, {
      state: { email: localStorage.getItem("userEmail") },
    });
  };

  const handlePayeeChange = (e:any)=>{
    const {value,name} = e.target;
    setPayeeName(value)
  }

  const createFavouritePayee = async () => {
    setPayeeAddLoading(true)
    favuriteData.nick_name=payeeName
    const payLoad = {
      recipient_name: "suresh kanna ",
      account_number: "1234567890",
      bank_id: 1,
      mobile_number: "+1234567890",
      is_favorite: true,
      nick_name: payeeName,
      action: "Add",
    };
    const payeeAddRes = await addPayee(favuriteData);
    if(payeeAddRes.code === 100){
      setPayeeAddLoading(false)
      handleClose()
      toast.success("Favourite add succesfully");
     
    }else{
      setPayeeAddLoading(false)
      toast.error("Favourite add failed");
    }
  };

  return (
    <>
      <div className={`confirmation-dialog-app ${isOpenView ? "show" : ""}`}>
        <div className="d-flex justify-content-end align-items-center">
          <img className="pe-2 close-icon" src={group1} onClick={handleClose} />
        </div>
        <div className="add-favourite-text">Add to favourite </div>
        <div className="d-flex justify-content-center topic-app4">
          Save transaction details for the future transactions
        </div>
        <div className="vw-100">
          <div className="email-label-div-text d-flex justify-content-start align-items-center">
            Write a name
          </div>
          <input
            type="text"
            className="div-type-input"
            placeholder="Write a name"
            value={payeeName}
            onChange={handlePayeeChange}
          />
        </div>
        <div className="d-flex justify-content-center buttons-container-app2">
          <button className="button-cancel-app2" onClick={handleClose}>
            Cancel
          </button>
          <button
            className={`delete-button-app2 ${
              isButtonLoading ? "disabled-area" : ""
            }`}
            onClick={() => {createFavouritePayee()}}
          >
            {payeeAddLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Saving...</span>
              </>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>

      {isOpenView && <div className="confirmation-overlay"></div>}
    </>
  );
};

export default AddFavouriteDialog;
