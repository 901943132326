import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Profile.css";
import delete_icon from "../../../assets/delete-icon.png";
import edit_icon from "./../../../assets/edit-icon.png";
import Button from "../../../utilities/button/Button";
import ToggleButton from "../../../utilities/toggle-btn/ToggleButton";
import {
  billingAddressAdd,
  deleteBillingAddress,
  deleteShippingAddress,
  editBillingAddress,
  editShippingAddress,
  getBillingAddress,
  getShippingAddress,
  profileDetailsGet,
  profileImgUpload,
  profileUpdate,
  shippingAddressAdd,
} from "../../../services/profile";
import LeftOffCanvas from "../../../utilities/left-offcanvas/LeftOffCanvas";
import update_photo_icon from "../../../assets/Group 2959.png";
import unknow_img from "../../../assets/image-line.png";
import plus_icon from "../../../assets/Group.png";
import NavBar from "../../../utilities/navbar/NavBar";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import DeleteConfirmationDialog from "../../../utilities/delete-confirmation-dialog/DeleteConfirmationDialog";
import { getNames } from "country-list";

const Profile = () => {
  const today = new Date().toISOString().split("T")[0];
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const [isUpdate, setIsUpdate] = useState(false); // Track if it's an update or create operation
  const [pinCode, setPinCode] = useState(false);
  const [passkey, setPasskey] = useState(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [defaultBillingAddress, setDefaultBillingAddress] = useState(false);
  const [defaultShippingAddress, setDefaultShippingAddress] = useState(false);
  const [defaultEditBillingAddress, setDefaultEditBillingAddress] =
    useState(false);
  const [defaultEditShippingAddress, setDefaultEditShippingAddress] =
    useState(false);
  const [passwordResetCanvasOpen, setPasswordResetCanvasOpen] = useState(false);
  const [billingAddressCanvasOpen, setBillingAddressCanvasOpen] =
    useState(false);
  const [detailsSaveLoading, setdetailsSaveLoading] = useState(false);
  const [billingAddressAddLoading, setBillingAddressCanvasAddLoading] =
    useState(false);
  const [shippingAddressCanvasOpen, setShippingAddressCanvasOpen] =
    useState(false);
  const [editShippingAddressCanvasOpen, seteditShippingAddressCanvasOpen] =
    useState(false);
  const [editBillingAddressCanvasOpen, setEditBillingAddressCanvasOpen] =
    useState(false);
  const [shippingAddressAddLoading, setShippingAddressCanvasAddLoading] =
    useState(false);
  const [editBillingAddressLoading, setEditBillingAddressCanvasLoading] =
    useState(false);
  const [editShippingAddressLoading, setEditShippingAddressCanvasLoading] =
    useState(false);
  const [isDeleteBillingAddress, setIsDeleteBillingAddress] = useState(false);
  const [isDeleteShippingAddress, setIsDeleteShippingAddress] = useState(false);
  const [deleteAddressId, setDeleteAddressId] = useState(0);
  const [editAddressId, setEditAddressId] = useState(0);
  const [addressDeleteBtnLoading, setAddressDeleteBtnLoading] = useState(false);

  const [userDetails, setUserDetails] = useState<any>({});
  const [imageFile, setImageFile] = useState(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [userId, setUserId] = useState(0);
  const [billingAddresses, setBillingAddreses] = useState([]);
  const [shippingAddresses, setShippingAddreses] = useState([]);
  const [countries, setCountries] = useState<any>([]);

  interface PasswordResetFormValues {
    password: string;
    new_password: string;
    confirm_password: string;
  }
  useEffect(() => {
    getUserDetails();
    getBillingAddresses();
    getShippingAddresses();
    const countryList = getNames();
    setCountries(countryList);
  }, []);

  const getUserDetails = async () => {
    const userDetailsRes = await profileDetailsGet(email);
    if (userDetailsRes.code === 100) {
      console.log(userDetailsRes.data);
      setUserDetails(userDetailsRes.data);
      if (userDetailsRes.data) {
        setUserId(userDetailsRes.data.id);
        formik.values.firstName = userDetailsRes.data.first_name;
        formik.values.lastName = userDetailsRes.data.last_name;
        formik.values.contactNumber = userDetailsRes.data.mobile_number;
        formik.values.email = userDetailsRes.data.email;
        formik.values.dob = userDetailsRes.data.date_of_birth;
        formik.values.idPassportNumber =
          userDetailsRes.data.id_or_passport_number;
        formik.values.gender = userDetailsRes.data.gender;
        setPinCode(userDetailsRes.data.is_pin_code);
        setPasskey(userDetailsRes.data.is_passkey);
        setTwoFactorAuth(userDetailsRes.data.is_two_factor_auth_required);
      }
    }
  };

  const getBillingAddresses = async () => {
    const addresRes = await getBillingAddress();
    if (addresRes.code === 100) {
      setBillingAddreses(addresRes.data);
    }
  };
  const getShippingAddresses = async () => {
    const addresRes = await getShippingAddress();
    if (addresRes.code === 100) {
      setShippingAddreses(addresRes.data);
    }
  };

  const updateProfilePhoto = async (file: any) => {
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    const dpUploadRes = await profileImgUpload(formData);
    if (dpUploadRes.code === 100) {
      toast.success("Profile photo upload successfully");
      getUserDetails();
    } else {
      toast.error("Profile photo upload failed");
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      contactNumber: "",
      email: "",
      dob: "",
      idPassportNumber: "",
      gender: "male",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required").matches(/^[A-Za-z]+$/, "Only letters A-Z are allowed"),
      lastName: Yup.string().required("Last name is required").matches(/^[A-Za-z]+$/, "Only letters A-Z are allowed"),
      contactNumber: Yup.string().required("Contact number is required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      dob: Yup.date().required("Date of birth is required"),
      idPassportNumber: Yup.string().required("Required"),
      gender: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setdetailsSaveLoading(true);
      const profileData = {
        first_name: values.firstName,
        last_name: values.lastName,
        mobile_number: values.contactNumber,
        date_of_birth: values.dob,
        id_or_passport_number: values.idPassportNumber,
        gender: values.gender,
        postal_address: values.idPassportNumber,
        is_pin_code: pinCode,
        is_passkey: passkey,
        is_two_factor_auth_required: twoFactorAuth,
        profile_photo_url: userDetails.profile_photo_url,
      };
      try {
        const upadateRes = await profileUpdate(profileData, email);

        if (upadateRes.code === 100) {
          setdetailsSaveLoading(false);
          toast.success("Profile update successfully");
          resetForm();
          navigate(-1);
          console.log("Profile saved successfully");
        } else {
          setdetailsSaveLoading(false);
          toast.error("Profile update failed");
          console.error("Failed to save profile");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  const formikBillingAddress = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      contactNumber: "",
      address: "",
      country: "",
      postalCode: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required").matches(/^[A-Za-z]+$/, "Only letters A-Z are allowed"),
      lastName: Yup.string().required("Required").matches(/^[A-Za-z]+$/, "Only letters A-Z are allowed"),
      contactNumber: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      country: Yup.string().required("Required"),
      postalCode: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setBillingAddressCanvasAddLoading(true);
      const addressData = {
        f_name: values.firstName,
        l_name: values.lastName,
        address_line_1: values.address,
        address_line_2: "",
        address_line_3: "",
        address_line_4: "",
        address_line_5: "",
        address_line_6: values.contactNumber,
        city: "",
        province: "",
        postal_code: values.postalCode,
        country: values.country,
        is_default: defaultBillingAddress,
        user: userId,
      };
      try {
        const addRes = await billingAddressAdd(addressData);
        console.log(addRes.code);

        if (addRes.code === 100) {
          getBillingAddresses();
          setBillingAddressCanvasAddLoading(false);
          setBillingAddressCanvasOpen(false);
          toast.success("Billing address add successfully");
          console.log("Billing address add successfully");
          resetForm();
          formikBillingAddress.resetForm();
        } else {
          setBillingAddressCanvasAddLoading(false);
          setBillingAddressCanvasOpen(false);
          toast.success("Failed to add billing address");
          console.error("Failed to add billing address");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  const formikShippingAddress = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      contactNumber: "",
      address: "",
      country: "Sri Lanka",
      postalCode: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required").matches(/^[A-Za-z]+$/, "Only letters A-Z are allowed"),
      lastName: Yup.string().required("Required").matches(/^[A-Za-z]+$/, "Only letters A-Z are allowed"),
      contactNumber: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      country: Yup.string().required("Required"),
      postalCode: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setShippingAddressCanvasAddLoading(true);
      const addressData = {
        f_name: values.firstName,
        l_name: values.lastName,
        address_line_1: values.address,
        address_line_2: "",
        address_line_3: "",
        address_line_4: "",
        address_line_5: "",
        address_line_6: values.contactNumber,
        city: "",
        province: "",
        postal_code: values.postalCode,
        country: values.country,
        is_default: defaultShippingAddress,
        user: userId,
      };
      try {
        const addRes = await shippingAddressAdd(addressData);
        console.log(addRes.code);

        if (addRes.code === 100) {
          getShippingAddresses();
          setShippingAddressCanvasAddLoading(false);
          setShippingAddressCanvasOpen(false);
          console.log("Shipping address add successfully");
          resetForm();
          formikShippingAddress.resetForm();
        } else {
          setShippingAddressCanvasAddLoading(false);
          setShippingAddressCanvasOpen(false);
          console.error("Failed to add Shipping address");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  const formikShippingAddressEdit = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      contactNumber: "",
      address: "",
      country: "",
      postalCode: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      contactNumber: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      country: Yup.string().required("Required"),
      postalCode: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      setEditShippingAddressCanvasLoading(true);
      const addressData = {
        f_name: values.firstName,
        l_name: values.lastName,
        address_line_1: values.address,
        address_line_2: "",
        address_line_3: "",
        address_line_4: "",
        address_line_5: "",
        address_line_6: values.contactNumber,
        city: "",
        province: "",
        postal_code: values.postalCode,
        country: values.country,
        is_default: defaultEditShippingAddress,
        user: userId,
      };
      try {
        const addRes = await editShippingAddress(editAddressId, addressData);
        console.log(addRes.code);

        if (addRes.code === 100) {
          getShippingAddresses();
          setEditShippingAddressCanvasLoading(false);
          seteditShippingAddressCanvasOpen(false);
          console.log("Shipping address add successfully");
        } else {
          setEditShippingAddressCanvasLoading(false);
          seteditShippingAddressCanvasOpen(false);
          console.error("Failed to add Shipping address");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });
  const formikBillingAddressEdit = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      contactNumber: "",
      address: "",
      country: "",
      postalCode: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      contactNumber: Yup.string().required("Required"),
      address: Yup.string().required("Required"),
      country: Yup.string().required("Required"),
      postalCode: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      setEditBillingAddressCanvasLoading(true);
      const addressData = {
        f_name: values.firstName,
        l_name: values.lastName,
        address_line_1: values.address,
        address_line_2: "",
        address_line_3: "",
        address_line_4: "",
        address_line_5: "",
        address_line_6: values.contactNumber,
        city: "",
        province: "",
        postal_code: values.postalCode,
        country: values.country,

        is_default: defaultEditBillingAddress,
        user: userId,
      };
      try {
        const addRes = await editBillingAddress(editAddressId, addressData);
        console.log(addRes.code);

        if (addRes.code === 100) {
          getBillingAddresses();
          setEditBillingAddressCanvasLoading(false);
          setEditBillingAddressCanvasOpen(false);
          console.log("Shipping address add successfully");
        } else {
          setEditBillingAddressCanvasLoading(false);
          setEditBillingAddressCanvasOpen(false);
          console.error("Failed to add Shipping address");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });
  const handleToggle = (toggleName: any, isToggled: any) => {
    if (toggleName === "pinCode") setPinCode(isToggled);
    else if (toggleName === "passkey") setPasskey(isToggled);
    else if (toggleName === "twoFactorAuth") setTwoFactorAuth(isToggled);
    else if (toggleName === "bill") setDefaultBillingAddress(isToggled);
    else if (toggleName === "ship") setDefaultShippingAddress(isToggled);
    else if (toggleName === "billEdit") setDefaultEditBillingAddress(isToggled);
    else if (toggleName === "shipEdit")
      setDefaultEditShippingAddress(isToggled);
  };

  const formikPasswordReset = useFormik({
    initialValues: {
      password: "",
      new_password: "",
      confirm_password: "",
    },
    onSubmit: async (values) => {
      console.log("Password Reset successfully");
    },
    validate: (values) => {
      const errors: Partial<PasswordResetFormValues> = {};
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#].{7,}$/;

      if (!values.password) {
        errors.password = "New password is required";
      } else if (values.password.length < 8) {
        errors.password = "Password must be at least 8 characters";
      } else if (!passwordRegex.test(values.password)) {
        errors.password = "Password should be strong";
      }

      if (!values.new_password) {
        errors.new_password = "New password is required";
      } else if (values.new_password.length < 8) {
        errors.new_password = "New password must be at least 8 characters";
      } else if (!passwordRegex.test(values.new_password)) {
        errors.new_password = "New password should be strong";
      }

      if (values.new_password !== values.confirm_password) {
        //errors.new_password = "Confirm password doesn't match";
        errors.confirm_password = "Confirm password doesn't match";
      }

      return errors;
    },
  });
  const handleImageChange = (event: any) => {
    setImageFile(event.currentTarget.files[0]);
    updateProfilePhoto(event.currentTarget.files[0]);
  };
  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleGenderChange = (event: any) => {
    formik.setFieldValue("gender", event.target.value); // Update Formik's gender value
  };

  const closeBillingDeleteDialog = () => {
    setIsDeleteBillingAddress(false);
  };
  const closeShippingDeleteDialog = () => {
    setIsDeleteShippingAddress(false);
  };

  const billingAddressDelete = async (id: any) => {
    setAddressDeleteBtnLoading(true);
    const deleteRes = await deleteBillingAddress(id);
    if (deleteRes.code === 100) {
      toast.success("Billing address delete successfully");
      getBillingAddresses();
      setIsDeleteBillingAddress(false);
      setAddressDeleteBtnLoading(false);
    } else {
      toast.error("Billing address delete failed");
      setAddressDeleteBtnLoading(false);
    }
  };

  const shippingAddressDelete = async (id: any) => {
    setAddressDeleteBtnLoading(true);
    const deleteRes = await deleteShippingAddress(id);
    if (deleteRes.code === 100) {
      toast.success("Shipping address delete successfully");
      getShippingAddresses();
      setIsDeleteShippingAddress(false);
      setAddressDeleteBtnLoading(false);
    } else {
      toast.error("Shipping address delete failed");
      setAddressDeleteBtnLoading(false);
    }
  };
  return (
    <div className="main-profile-div">
      <NavBar verifiedEmail={email} />
      <div className="d-flex justify-content-center align-items-center vw-100 mt-3">
        {/* <div className="second-main-div"> */}
        <form className="second-main-div" onSubmit={formik.handleSubmit}>
          <div className="d-flex justify-content-between align-items-center vw-100">
            <div className="d-flex flex-column justify-content-start align-items-start">
              <div className="oneID-profile-text">OneID Profile</div>
              <div className="oneID-profile-sub-text">
                OneId profile is for your easyness
              </div>
            </div>
            <div
              className={`button-div ${
                !formik.dirty || !formik.isValid ? "disabled-area" : ""
              }`}
            >
              {/* disabled={} */}
              <Button
                label="Cancel"
                type="button"
                onClick={() => {
                  getUserDetails();
                  getBillingAddresses();
                  getShippingAddresses();
                  navigate(-1);
                }}
                className="cancel-btn"
              />
              <Button
                label="Save"
                type="submit"
                className="save-btn"
                onClick={() => {}}
                loading={detailsSaveLoading}
              />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-start align-items-start vw-100 mt-3">
            <div className="profile-details-text">Profile details</div>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <div className="profile-photo-div">
                {userDetails.profile_photo_url === "" ? (
                  <div className="profile-unknow-img">
                    <img
                      className=""
                      src={
                        userDetails.profile_photo_url === ""
                          ? unknow_img
                          : userDetails.profile_photo_url
                      }
                      alt=""
                      width={23}
                    />
                  </div>
                ) : (
                  // <div className="photo-container">
                  //   <img
                  //     className="photo-container-img2"
                  //     src={
                  //       userDetails.profile_photo_url === ""
                  //         ? unknow_img
                  //         : userDetails.profile_photo_url
                  //     }
                  //     alt=""
                  //   />
                  // </div>
                  <div className="photo-container">
                    <img
                      className="photo-container-img2"
                      src={
                        userDetails.profile_photo_url === ""
                          ? unknow_img
                          : userDetails.profile_photo_url
                      }
                      alt=""
                      width={23}
                    />
                  </div>
                )}

                <img
                  className="photo-img"
                  src={update_photo_icon}
                  alt="Update"
                  width={31}
                  onClick={handleImageClick}
                  style={{ cursor: "pointer" }}
                />
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
              </div>
              <div className="upload-text">Profile picture</div>
            </div>
          </div>
          <div className="form-div mt-3">
            <div>
              <div className="email-label-div-text">First name</div>
              <input
                type="text"
                className="div-type-input"
                placeholder="Enter your first name"
                {...formik.getFieldProps("firstName")}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className="formik-errors">{formik.errors.firstName}</div>
              ) : null}
            </div>
            <div>
              <div className="email-label-div-text">Last name</div>
              <input
                type="text"
                className="div-type-input"
                placeholder="Enter your last name"
                {...formik.getFieldProps("lastName")}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className="formik-errors">{formik.errors.lastName}</div>
              ) : null}
            </div>
            <div>
              <div className="email-label-div-text">Contact number</div>
              <input
                type="text"
                className="div-type-input"
                placeholder="000 0000 00000"
                inputMode="numeric"
                // pattern="[0-9]*"
                {...formik.getFieldProps("contactNumber")}
              />
              {formik.touched.contactNumber && formik.errors.contactNumber ? (
                <div className="formik-errors">
                  {formik.errors.contactNumber}
                </div>
              ) : null}
            </div>
            <div>
              <div className="email-label-div-text">Email address</div>
              <input
                type="email"
                className="div-type-input"
                placeholder="Write your email here"
                disabled
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="formik-errors">{formik.errors.email}</div>
              ) : null}
            </div>
            <div>
              <div className="email-label-div-text">Date of birth</div>
              {/* <input
                type="text"
                max={today}
                style={{
                  textAlign: "left",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
                className="div-type-input"
                placeholder="DD : MM : YYYY"
                onFocus={(e) => (e.currentTarget.type = "date")}
                
                {...formik.getFieldProps("dob")}
              /> */}
              <input
                type="text"
                className="div-type-input"
                placeholder="DD/MM/YYYY"
                maxLength={10}
                value={formik.values.dob}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

                  if (value.length > 2) {
                    value = value.substring(0, 2) + "/" + value.substring(2);
                  }

                  if (value.length > 5) {
                    value = value.substring(0, 5) + "/" + value.substring(5, 9);
                  }

                  formik.setFieldValue("dob", value);
                }}
                onBlur={formik.handleBlur}
                inputMode="numeric"
          // pattern="[0-9]*"
              />

              {formik.touched.dob && formik.errors.dob ? (
                <div className="formik-errors">{formik.errors.dob}</div>
              ) : null}
            </div>
            <div>
              <div className="email-label-div-text">ID/Passport number</div>
              <input
                type="text"
                className="div-type-input"
                placeholder="Write your ID/Passport number here"
                {...formik.getFieldProps("idPassportNumber")}
              />
              {formik.touched.idPassportNumber &&
              formik.errors.idPassportNumber ? (
                <div className="formik-errors">
                  {formik.errors.idPassportNumber}
                </div>
              ) : null}
            </div>
            <div>
              <div className="email-label-div-text">Gender</div>
              <select
                style={{
                  fontSize: "14px",
                  cursor: "pointer",
                  paddingTop: "12px",
                }}
                value={formik.values.gender}
                onChange={handleGenderChange}
                name="bot_token"
                className="div-type-input pl-2"
              >
                <option className="pt-2 pb-1" value="default">
                  Select your gender
                </option>
                <option className="pt-2 pb-1" value="male">
                  Male
                </option>
                <option className="pt-2 pb-1" value="female">
                  Female
                </option>
              </select>
              {formik.touched.gender && formik.errors.gender ? (
                <div className="formik-errors">{formik.errors.gender}</div>
              ) : null}
            </div>
            <div></div>
            <div>
              <div
                className="email-label-div-text"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setBillingAddressCanvasOpen(true);
                }}
              >
                <span>
                  <img className="" src={plus_icon} alt="" width={20} />
                </span>{" "}
                Billing address
              </div>
              {billingAddresses.length !== 0 &&
                billingAddresses?.map((data: any, index: any) => (
                  <div key={index} className="address-card mb-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="address-card-name">
                        {data?.f_name} {data?.l_name}
                      </div>
                      <div className="d-flex justify-content-end align-items-center">
                        <img
                          src={edit_icon}
                          alt=""
                          width={16}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => {
                            setEditBillingAddressCanvasOpen(true);
                            setEditAddressId(data?.id);
                            formikBillingAddressEdit.values.firstName =
                              data?.f_name;
                            formikBillingAddressEdit.values.lastName =
                              data?.l_name;
                            formikBillingAddressEdit.values.address =
                              data?.address_line_1;
                            formikBillingAddressEdit.values.contactNumber =
                              data?.address_line_6;
                            formikBillingAddressEdit.values.country =
                              data?.country;
                            formikBillingAddressEdit.values.postalCode =
                              data?.postal_code;
                            setDefaultEditBillingAddress(data?.is_default);
                          }}
                        />
                        <img
                          src={delete_icon}
                          alt=""
                          width={16}
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          onClick={() => {
                            setDeleteAddressId(data.id);
                            setIsDeleteBillingAddress(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="address-card-text">
                      {data?.address_line_1}
                      {","}
                      {data?.country}
                      <br></br>
                      {data?.postal_code}
                      <br></br>
                      {data?.address_line_6}
                    </div>
                    <div className="default-text">
                      {data.is_default ? "Default" : ""}
                    </div>
                  </div>
                ))}
            </div>
            <div>
              <div
                className="email-label-div-text"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShippingAddressCanvasOpen(true);
                }}
              >
                <span>
                  <img className="" src={plus_icon} alt="" width={20} />
                </span>{" "}
                Shipping address
              </div>
              {shippingAddresses.length !== 0 &&
                shippingAddresses?.map((data: any, index: any) => (
                  <div key={index} className="address-card mb-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="address-card-name">
                        {data?.f_name} {data?.l_name}
                      </div>
                      <div className="d-flex justify-content-end align-items-center">
                        <img
                          src={edit_icon}
                          alt=""
                          width={16}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => {
                            seteditShippingAddressCanvasOpen(true);
                            setEditAddressId(data?.id);
                            formikShippingAddressEdit.values.firstName =
                              data?.f_name;
                            formikShippingAddressEdit.values.lastName =
                              data?.l_name;
                            formikShippingAddressEdit.values.address =
                              data?.address_line_1;
                            formikShippingAddressEdit.values.contactNumber =
                              data?.address_line_6;
                            formikShippingAddressEdit.values.country =
                              data?.country;
                            formikShippingAddressEdit.values.postalCode =
                              data?.postal_code;
                            setDefaultEditShippingAddress(data?.is_default);
                          }}
                        />
                        <img
                          src={delete_icon}
                          alt=""
                          width={16}
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          onClick={() => {
                            setDeleteAddressId(data.id);
                            setIsDeleteShippingAddress(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="address-card-text">
                      {data?.address_line_1}
                      {","}
                      {data?.country}
                      <br></br>
                      {data?.postal_code}
                      <br></br>
                      {data?.address_line_6}
                    </div>
                    <div className="default-text">
                      {data?.is_default ? "Default" : ""}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="d-flex flex-column justify-content-start align-items-start vw-100 mt-3">
            <div className="profile-details-text">
              Authentications
              <span className="coming-soon-text">Coming soon</span>
            </div>
            <div className="toggle-div disabled-area">
              <div className="d-flex justify-content-start align-items-center">
                <ToggleButton
                  onToggle={(isToggled) => handleToggle("pinCode", isToggled)}
                  initialState={pinCode}
                />
                <div className="toggle-text ml-3">PIN code</div>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <ToggleButton
                  onToggle={(isToggled) => handleToggle("passkey", isToggled)}
                  initialState={passkey}
                />
                <div className="toggle-text ml-3">Passkey</div>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <ToggleButton
                  onToggle={(isToggled) =>
                    handleToggle("twoFactorAuth", isToggled)
                  }
                  initialState={twoFactorAuth}
                />
                <div className="toggle-text ml-3">
                  Two factor authentication
                </div>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-start align-items-start vw-100 mt-3 ">
              <div className="profile-details-text">
                Settings <span className="coming-soon-text">Coming soon</span>
              </div>
              <div
                className="password-reset-text disabled-area"
                onClick={() => {
                  setPasswordResetCanvasOpen(true);
                }}
              >
                Password reset
              </div>
            </div>
          </div>
          <div
            className={`button-div2 ${
              !formik.dirty || !formik.isValid ? "disabled-area" : ""
            }`}
          >
            <Button
              label="Cancel"
              type="button"
              onClick={() => {
                getUserDetails();
                getBillingAddresses();
                getShippingAddresses();
                navigate(-1);
              }}
              className="cancel-btn"
            />
            <Button
              label="Save"
              type="submit"
              className="save-btn"
              onClick={() => {}}
              loading={detailsSaveLoading}
            />
          </div>
        </form>
        {/* </div> */}
      </div>
      {passwordResetCanvasOpen && (
        <LeftOffCanvas
          onClose={() => {
            setPasswordResetCanvasOpen(false);
          }}
          heading={"Change password"}
        >
          <div className="canvas-sub-title">
            Your new password must be different from previous used password.
          </div>
          <form onSubmit={formikPasswordReset.handleSubmit}>
            <div>
              <div className="offcanvas-label-div-text">Current password</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Current password"
                  {...formikPasswordReset.getFieldProps("password")}
                />
              </div>
              {formikPasswordReset.touched.password &&
              formikPasswordReset.errors.password ? (
                <div className="formik-errors">
                  {formikPasswordReset.errors.password}
                </div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">New password</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="New Password"
                  {...formikPasswordReset.getFieldProps("new_password")}
                />
              </div>

              {formikPasswordReset.touched.new_password &&
              formikPasswordReset.errors.new_password ? (
                <div className="formik-errors">
                  {formikPasswordReset.errors.new_password}
                </div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Confirm password</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Confirm password"
                  {...formikPasswordReset.getFieldProps("confirm_password")}
                />
              </div>
              {formikPasswordReset.touched.confirm_password &&
              formikPasswordReset.errors.confirm_password ? (
                <div className="formik-errors">
                  {formikPasswordReset.errors.confirm_password}
                </div>
              ) : null}
            </div>
            <Button
              label="Save"
              type="submit"
              className="password-reset-btn"
              onClick={() => {}}
            />
          </form>
        </LeftOffCanvas>
      )}
      {billingAddressCanvasOpen && (
        <LeftOffCanvas
          onClose={() => {
            setBillingAddressCanvasOpen(false);
            formikBillingAddress.resetForm();
          }}
          heading={"Add billing address"}
        >
          <form onSubmit={formikBillingAddress.handleSubmit}>
            <div>
              <div className="offcanvas-label-div-text">First name</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="First name"
                  {...formikBillingAddress.getFieldProps("firstName")}
                />
              </div>
              {formikBillingAddress.touched.firstName &&
              formikBillingAddress.errors.firstName ? (
                <div className="formik-errors">
                  {formikBillingAddress.errors.firstName}
                </div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Last name</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="First name"
                  {...formikBillingAddress.getFieldProps("lastName")}
                />
              </div>
              {formikBillingAddress.touched.lastName &&
              formikBillingAddress.errors.lastName ? (
                <div className="formik-errors">
                  {formikBillingAddress.errors.lastName}
                </div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Contact number</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Contact Number"
                  {...formikBillingAddress.getFieldProps("contactNumber")}
                  inputMode="numeric"
          // pattern="[0-9]*"
                />
              </div>
              {formikBillingAddress.touched.contactNumber &&
              formikBillingAddress.errors.contactNumber ? (
                <div className="formik-errors">
                  {formikBillingAddress.errors.contactNumber}
                </div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Address</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="First name"
                  {...formikBillingAddress.getFieldProps("address")}
                />
              </div>
              {formikBillingAddress.touched.address &&
              formikBillingAddress.errors.address ? (
                <div className="formik-errors">
                  {formikBillingAddress.errors.address}
                </div>
              ) : null}
            </div>
            {/* <div>
              <div className="offcanvas-label-div-text">Country</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Country"
                  {...formikBillingAddress.getFieldProps("country")}
                />
              </div>
              {formikBillingAddress.touched.country &&
              formikBillingAddress.errors.country ? (
                <div className="formik-errors">
                  {formikBillingAddress.errors.country}
                </div>
              ) : null}
            </div> */}
            <div>
              <div className="offcanvas-label-div-text">Country</div>
              <div className="div-type-input2">
                <select
                  id="country"
                  name="country"
                  className="bot-name-div-type-input"
                  value={formikBillingAddress.values.country}
                  onChange={formikBillingAddress.handleChange}
                  onBlur={formikBillingAddress.handleBlur}
                >
                  <option>Country</option>
                  {countries.map((country: any) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>

              {formikBillingAddress.touched.country &&
              formikBillingAddress.errors.country ? (
                <div className="formik-errors">{formikBillingAddress.errors.country}</div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Postal code</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Postal code"
                  {...formikBillingAddress.getFieldProps("postalCode")}
                />
              </div>
              {formikBillingAddress.touched.postalCode &&
              formikBillingAddress.errors.postalCode ? (
                <div className="formik-errors">
                  {formikBillingAddress.errors.postalCode}
                </div>
              ) : null}
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3 mb-3">
              <ToggleButton
                onToggle={(isToggled) => handleToggle("bill", isToggled)}
                initialState={defaultBillingAddress}
              />
              <div className="toggle-text ml-3">Is default address</div>
            </div>
            <Button
              label="Save"
              type="submit"
              className="address-save-btn"
              onClick={() => {}}
              loading={billingAddressAddLoading}
            />
          </form>
        </LeftOffCanvas>
      )}
      {shippingAddressCanvasOpen && (
        <LeftOffCanvas
          onClose={() => {
            setShippingAddressCanvasOpen(false);
            formikShippingAddress.resetForm();
          }}
          heading={"Add shipping address"}
        >
          <form onSubmit={formikShippingAddress.handleSubmit}>
            <div>
              <div className="offcanvas-label-div-text">First name</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="First name"
                  {...formikShippingAddress.getFieldProps("firstName")}
                />
              </div>
              {formikShippingAddress.touched.firstName &&
              formikShippingAddress.errors.firstName ? (
                <div className="formik-errors">
                  {formikShippingAddress.errors.firstName}
                </div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Last name</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="First name"
                  {...formikShippingAddress.getFieldProps("lastName")}
                />
              </div>
              {formikShippingAddress.touched.lastName &&
              formikShippingAddress.errors.lastName ? (
                <div className="formik-errors">
                  {formikShippingAddress.errors.lastName}
                </div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Contact number</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Contact Number"
                  {...formikShippingAddress.getFieldProps("contactNumber")}
                  inputMode="numeric"
          // pattern="[0-9]*"
                />
              </div>
              {formikShippingAddress.touched.contactNumber &&
              formikShippingAddress.errors.contactNumber ? (
                <div className="formik-errors">
                  {formikShippingAddress.errors.contactNumber}
                </div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Address</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="First name"
                  {...formikShippingAddress.getFieldProps("address")}
                />
              </div>
              {formikShippingAddress.touched.address &&
              formikShippingAddress.errors.address ? (
                <div className="formik-errors">
                  {formikShippingAddress.errors.address}
                </div>
              ) : null}
            </div>
            {/* <div>
              <div className="offcanvas-label-div-text">Country</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Country"
                  {...formikShippingAddress.getFieldProps("country")}
                />
              </div>
              {formikShippingAddress.touched.country &&
              formikShippingAddress.errors.country ? (
                <div className="formik-errors">
                  {formikShippingAddress.errors.country}
                </div>
              ) : null}
            </div> */}
            <div>
              <div className="offcanvas-label-div-text">Country</div>
              <div className="div-type-input2">
                <select
                  id="country"
                  name="country"
                  className="bot-name-div-type-input"
                  value={formikShippingAddress.values.country}
                  onChange={formikShippingAddress.handleChange}
                  onBlur={formikShippingAddress.handleBlur}
                >
                  <option>Country</option>
                  {countries.map((country: any) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>

              {formikShippingAddress.touched.country &&
              formikShippingAddress.errors.country ? (
                <div className="formik-errors">{formikShippingAddress.errors.country}</div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Postal code</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Postal code"
                  {...formikShippingAddress.getFieldProps("postalCode")}
                  
                />
              </div>
              {formikShippingAddress.touched.postalCode &&
              formikShippingAddress.errors.postalCode ? (
                <div className="formik-errors">
                  {formikShippingAddress.errors.postalCode}
                </div>
              ) : null}
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3 mb-3">
              <ToggleButton
                onToggle={(isToggled) => handleToggle("ship", isToggled)}
                initialState={defaultShippingAddress}
              />
              <div className="toggle-text ml-3">Is default address</div>
            </div>
            <Button
              label="Save"
              type="submit"
              className="address-save-btn"
              onClick={() => {}}
              loading={shippingAddressAddLoading}
            />
          </form>
        </LeftOffCanvas>
      )}
      {editShippingAddressCanvasOpen && (
        <LeftOffCanvas
          onClose={() => {
            seteditShippingAddressCanvasOpen(false);
          }}
          heading={"Edit shipping address"}
        >
          <form onSubmit={formikShippingAddressEdit.handleSubmit}>
            <div>
              <div className="offcanvas-label-div-text">First name</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="First name"
                  {...formikShippingAddressEdit.getFieldProps("firstName")}
                />
              </div>
              {formikShippingAddressEdit.touched.firstName &&
              formikShippingAddressEdit.errors.firstName ? (
                <div className="formik-errors">
                  {formikShippingAddressEdit.errors.firstName}
                </div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Last name</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="First name"
                  {...formikShippingAddressEdit.getFieldProps("lastName")}
                />
              </div>
              {formikShippingAddressEdit.touched.lastName &&
              formikShippingAddressEdit.errors.lastName ? (
                <div className="formik-errors">
                  {formikShippingAddressEdit.errors.lastName}
                </div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Contact number</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Contact Number"
                  {...formikShippingAddressEdit.getFieldProps("contactNumber")}
                />
              </div>
              {formikShippingAddressEdit.touched.contactNumber &&
              formikShippingAddressEdit.errors.contactNumber ? (
                <div className="formik-errors">
                  {formikShippingAddressEdit.errors.contactNumber}
                </div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Address</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="First name"
                  {...formikShippingAddressEdit.getFieldProps("address")}
                />
              </div>
              {formikShippingAddressEdit.touched.address &&
              formikShippingAddressEdit.errors.address ? (
                <div className="formik-errors">
                  {formikShippingAddressEdit.errors.address}
                </div>
              ) : null}
            </div>
            {/* <div>
              <div className="offcanvas-label-div-text">Country</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Country"
                  {...formikShippingAddressEdit.getFieldProps("country")}
                />
              </div>
              {formikShippingAddressEdit.touched.country &&
              formikShippingAddressEdit.errors.country ? (
                <div className="formik-errors">
                  {formikShippingAddressEdit.errors.country}
                </div>
              ) : null}
            </div> */}
            <div>
              <div className="offcanvas-label-div-text">Country</div>
              <div className="div-type-input2">
                <select
                  id="country"
                  name="country"
                  className="bot-name-div-type-input"
                  value={formikShippingAddressEdit.values.country}
                  onChange={formikShippingAddressEdit.handleChange}
                  onBlur={formikShippingAddressEdit.handleBlur}
                >
                  {countries.map((country: any) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>

              {formikShippingAddressEdit.touched.country &&
              formikShippingAddressEdit.errors.country ? (
                <div>{formikShippingAddressEdit.errors.country}</div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Postal code</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Postal code"
                  {...formikShippingAddressEdit.getFieldProps("postalCode")}
                />
              </div>
              {formikShippingAddressEdit.touched.postalCode &&
              formikShippingAddressEdit.errors.postalCode ? (
                <div className="formik-errors">
                  {formikShippingAddressEdit.errors.postalCode}
                </div>
              ) : null}
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3 mb-3">
              <ToggleButton
                onToggle={(isToggled) => handleToggle("shipEdit", isToggled)}
                initialState={defaultEditShippingAddress}
              />
              <div className="toggle-text ml-3">Is default address</div>
            </div>
            <Button
              label="Save"
              type="submit"
              className="address-save-btn"
              onClick={() => {}}
              loading={editShippingAddressLoading}
            />
          </form>
        </LeftOffCanvas>
      )}
      {editBillingAddressCanvasOpen && (
        <LeftOffCanvas
          onClose={() => {
            setEditBillingAddressCanvasOpen(false);
          }}
          heading={"Edit billing address"}
        >
          <form onSubmit={formikBillingAddressEdit.handleSubmit}>
            <div>
              <div className="offcanvas-label-div-text">First name</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="First name"
                  {...formikBillingAddressEdit.getFieldProps("firstName")}
                />
              </div>
              {formikBillingAddressEdit.touched.firstName &&
              formikBillingAddressEdit.errors.firstName ? (
                <div className="formik-errors">
                  {formikBillingAddressEdit.errors.firstName}
                </div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Last name</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="First name"
                  {...formikBillingAddressEdit.getFieldProps("lastName")}
                />
              </div>
              {formikBillingAddressEdit.touched.lastName &&
              formikBillingAddressEdit.errors.lastName ? (
                <div className="formik-errors">
                  {formikBillingAddressEdit.errors.lastName}
                </div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Contact number</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Contact Number"
                  {...formikBillingAddressEdit.getFieldProps("contactNumber")}
                />
              </div>
              {formikBillingAddressEdit.touched.contactNumber &&
              formikBillingAddressEdit.errors.contactNumber ? (
                <div className="formik-errors">
                  {formikBillingAddressEdit.errors.contactNumber}
                </div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Address</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Address"
                  {...formikBillingAddressEdit.getFieldProps("address")}
                />
              </div>
              {formikBillingAddressEdit.touched.address &&
              formikBillingAddressEdit.errors.address ? (
                <div className="formik-errors">
                  {formikBillingAddressEdit.errors.address}
                </div>
              ) : null}
            </div>
            {/* <div>
              <div className="offcanvas-label-div-text">Country</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Country"
                  {...formikBillingAddressEdit.getFieldProps("country")}
                />
              </div>
              {formikBillingAddressEdit.touched.country &&
              formikBillingAddressEdit.errors.country ? (
                <div className="formik-errors">
                  {formikBillingAddressEdit.errors.country}
                </div>
              ) : null}
            </div> */}
            <div>
              <div className="offcanvas-label-div-text">Country</div>
              <div className="div-type-input2">
                <select
                  id="country"
                  name="country"
                  className="bot-name-div-type-input"
                  value={formikBillingAddressEdit.values.country}
                  onChange={formikBillingAddressEdit.handleChange}
                  onBlur={formikBillingAddressEdit.handleBlur}
                >
                  {countries.map((country: any) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>

              {formikBillingAddressEdit.touched.country &&
              formikBillingAddressEdit.errors.country ? (
                <div>{formikBillingAddressEdit.errors.country}</div>
              ) : null}
            </div>
            <div>
              <div className="offcanvas-label-div-text">Postal code</div>
              <div className="div-type-input2">
                <input
                  type="text"
                  className="bot-name-div-type-input"
                  placeholder="Postal code"
                  {...formikBillingAddressEdit.getFieldProps("postalCode")}
                />
              </div>
              {formikBillingAddressEdit.touched.postalCode &&
              formikBillingAddressEdit.errors.postalCode ? (
                <div className="formik-errors">
                  {formikBillingAddressEdit.errors.postalCode}
                </div>
              ) : null}
            </div>
            <div className="d-flex justify-content-start align-items-center mt-3 mb-3">
              <ToggleButton
                onToggle={(isToggled) => handleToggle("billEdit", isToggled)}
                initialState={defaultEditBillingAddress}
              />
              <div className="toggle-text ml-3">Is default address</div>
            </div>
            <Button
              label="Save"
              type="submit"
              className="address-save-btn"
              onClick={() => {}}
              loading={editBillingAddressLoading}
            />
          </form>
        </LeftOffCanvas>
      )}
      {isDeleteBillingAddress && (
        <DeleteConfirmationDialog
          deleteItemName="billing address"
          deleteFunction={() => {
            billingAddressDelete(deleteAddressId);
          }}
          onClose={closeBillingDeleteDialog}
          isButtonLoading={addressDeleteBtnLoading}
        />
      )}
      {isDeleteShippingAddress && (
        <DeleteConfirmationDialog
          deleteItemName="shipping address"
          deleteFunction={() => {
            shippingAddressDelete(deleteAddressId);
          }}
          onClose={closeShippingDeleteDialog}
          isButtonLoading={addressDeleteBtnLoading}
        />
      )}
    </div>
  );
};

export default Profile;
