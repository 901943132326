import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className='footer'>
      <div className='container'>
        <a href="http://" target="_blank" rel="noopener noreferrer">Privacy policy</a>, certified with ISO27001 
      </div>
    </footer>
  );
};

export default Footer;
