import React, { useState } from "react";
import "./Login.css";
import Button from "../../../utilities/button/Button";
import logo from "../../../assets/Logo3.jpg";
import eye_icon from "../../../assets/eye-line.png"
import { useNavigate } from "react-router-dom";
import { oneIdLogin, oneIdRefreshToken } from "../../../services/auth_api";
import toast from "react-hot-toast";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [isPasswordSee,setIsPasswordSee] = useState(false)
  const handleClick = () => {
    toast.error("Coming soon");
  };
  const [error, setError] = useState('');

  const handleChangeEmail = async (event: any) => {
    const { name, value } = event.target;
    setEmail(value);
    
  };

  const handleChangePassword = async (event: any) => {
    const { name, value } = event.target;
    setPassword(value);
  };

  const loginFunction = async () => {
    if (email === "") {
      toast.error("Email field can't be empty");
      return
    }
    if (password === "") {
      toast.error("Password field can't be empty");
      return
    }
    if (!validateEmail(email)) {
      setError('Invalid email address');
      return
    } else {
      setError('');
    }
    setLoginLoading(true);
    const loginPayload = {
      email: email,
      password: password,
    };

    const loginRes = await oneIdLogin(loginPayload);
    
    if (loginRes.code === 100) {
      localStorage.setItem("userEmail", email);
      localStorage.setItem("token", loginRes.data.access);
      localStorage.setItem("refreshtoken", loginRes.data.refresh);
      setLoginLoading(false);
      navigate(`/transaction-history`, { state: { email: email } });
      setTimeout(async () => {
        const payLoad = {
          refresh: loginRes.data.refresh,
        };
        const refreshTokenRes = await oneIdRefreshToken(payLoad);
        if (refreshTokenRes.code === 100) {
          localStorage.setItem("token", refreshTokenRes.data.access);
        }
      }, 3540000);
    } else {
      setLoginLoading(false);
      toast.error(loginRes.message);
    }
  };
  const validateEmail = (email:any) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="main-div">
        <div className="d-flex justify-content-center align-items-center mb-4">
          <img src={logo} alt="" width={140} />
        </div>
        <div className="d-flex justify-content-center align-items-center set-password-text">
          Login with OneID
        </div>
        <div className="description-text">
          Simple, secure one‑click payments.
        </div>
        <div className="password-label-div-text">Email</div>
        <div className="password-div-type ">
          <input
            type="email"
            name=""
            id=""
            className="password-div-type-input"
            placeholder="Write your email here"
            value={email}
            onChange={handleChangeEmail}
          />
          
        </div>
        {error && <p className="email-invalid-text-1">{error}</p>}
        <div className="password-label-div-text">Password</div>
        <div className="password-div-type ">
          <input
            type={isPasswordSee ? "text":"password"}
            name=""
            id=""
            className="password-div-type-input"
            placeholder="Write your password"
            value={password}
            onChange={handleChangePassword}
          />
          <span><img src={eye_icon} alt="" width={17} className="mr-2" style={{cursor:"pointer"}} onClick={()=>{
            setIsPasswordSee(!isPasswordSee)
          }}/></span>
        </div>

        <Button
          label="Login"
          type="submit"
          onClick={loginFunction}
          className="sign-in-button"
          loading={loginLoading}
        />
        <div className="d-flex justify-content-end align-items-center vw-100">
          <div
            className="forgot-password-text mt-2 mb-3"
            onClick={() => {
              navigate(`/forgot-password`);
            }}
          >
            Forgot password?
          </div>
        </div>
        <Button
          label="Login with passkey"
          type="submit"
          onClick={handleClick}
          className="passkey-sign-in-button vw-100"
        />
        <div className="d-flex justify-content-center align-items-center vw-100">
          <div
            className="dont-have-acc-text mt-3 mb-3"
            onClick={() => {
              navigate(`/`);
            }}
          >
            Don’t have an account yet? Register
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
