// import React, { useEffect, useState } from "react";
// import { initializeApp } from "firebase/app";
// import { cardTokenize } from '../api/card_tokenization';
// import {
//   getFirestore,
//   doc,
//   onSnapshot,
//   initializeFirestore,
// } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyDGxd3PoP01JN25cPXfgRsVoc-C6XE2FTk",
//   authDomain: "spemai-global.firebaseapp.com",
//   projectId: "spemai-global",
//   storageBucket: "spemai-global.appspot.com",
//   messagingSenderId: "864345120875",
//   appId: "1:864345120875:web:d33dbc1b2b02f8098dd12e",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// //const db = initializeFirestore(app, {}, "oneid");
// const db = getFirestore(app);

// interface CardTokenizationProps {
//     cardTokenId: string;
//     cardTokenizeCallBack:(data:any) => void;
  
// }

// const CardTokenizationListener: React.FC<CardTokenizationProps> = ({ cardTokenId,cardTokenizeCallBack }) => {
//   useEffect(() => {
//     const docRef = doc(db, "oneid", "card_tokenization");
//     console.log(docRef);
//     const unsubscribe = onSnapshot(docRef, (doc) => {
//       if (doc.exists()) {
//         const data = doc.data();
//         const transactionData = data[cardTokenId];
//         if (
//           transactionData
//         ) {
//           console.log("It's authenticated 1",transactionData);
//           cardTokenizeCallBack(transactionData)
//         }

        
//       } else {
//         console.log("No such document!");
//       }
//     });

//     return () => unsubscribe(); // Cleanup the listener on component unmount
//   }, [cardTokenId]);

//   return <div />;
// };

// export default CardTokenizationListener;
import React, { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDGxd3PoP01JN25cPXfgRsVoc-C6XE2FTk",
  authDomain: "spemai-global.firebaseapp.com",
  projectId: "spemai-global",
  storageBucket: "spemai-global.appspot.com",
  messagingSenderId: "864345120875",
  appId: "1:864345120875:web:d33dbc1b2b02f8098dd12e",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

interface CardTokenizationProps {
  cardTokenId: string;
  cardTokenizeCallBack: (data: any) => void;
}

const CardTokenizationListener: React.FC<CardTokenizationProps> = ({
  cardTokenId,
  cardTokenizeCallBack,
}) => {
  useEffect(() => {
    console.log("Card tokenization order id:", cardTokenId);
    // Define the Firestore document path
    const docRef = doc(db, "oneid", "mpgs", "card_tokenization", cardTokenId);

    // Listen for changes on the document
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        const resultData = data?.result; // Access the 'Result' field

        if (resultData === "SUCCESS") {
          console.log("Card tokenization result:", resultData);
          cardTokenizeCallBack(resultData);
        } else {
          console.log("Result field is missing or empty.");
          cardTokenizeCallBack(resultData);
        }
      } else {
        console.log("No such document!");
        cardTokenizeCallBack("EMPTY_DOC");
      }
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, [cardTokenId, cardTokenizeCallBack]);

  return <div />;
};

export default CardTokenizationListener;
