import React, { useEffect, useState } from "react";
import './TransactionFailedDialog.css';
import delete_popup_icon from "../../assets/delete_popup_icon.png";
import ceft_failed_icon from "../../assets/Featured icon (2).png"
import group1 from "../../assets/Group1.png";
import { useNavigate } from "react-router-dom";

interface TransactionFailedDialogProps {
  failedReason: string;
  isCeftFailed: boolean;
  onClose: () => void;
  isButtonLoading?: boolean;
}

const TransactionFailedDialog: React.FC<TransactionFailedDialogProps> = ({
  failedReason,
  isCeftFailed,
  onClose,
  isButtonLoading
}) => {
  const navigate = useNavigate();
  const [isOpenView, setIsOpenView] = useState(false);

  useEffect(() => {
    // Trigger the transition effect
    setTimeout(() => setIsOpenView(true), 0);
  }, []);

  const handleClose = () => {
    setIsOpenView(false);
    navigate(`/send-money`);
  };

  return (
    <>
      <div className={`confirmation-dialog-app4 ${isOpenView ? 'show' : ''}`}>
        <div className="d-flex justify-content-end align-items-center">
        <img
                className="pe-2 close-icon"
                src={group1}
                onClick={handleClose}
              />
        </div>
        <div className="row justify-content-center">
          {isCeftFailed?(<img className="pe-2 feature-icon-update" src={ceft_failed_icon} />):(<img className="pe-2 feature-icon-update" src={delete_popup_icon} />)}
          
        </div>
        <div className="d-flex justify-content-center topic-app2">
          {isCeftFailed?"Your transaction is being processed by our partner bank. You will receive a notification once the transfer is completed.":"Your fund transfer is unsuccessful"}
        
        </div>
        <div
          className="d-flex justify-content-center faied-reason-text"
          style={{ fontSize: "14px" }}
        >
          {failedReason !== ""?failedReason:''}
        </div>
        <div className="d-flex justify-content-center buttons-container-app">
          <button className="button-cancel-app4" onClick={handleClose}>
            Cancel
          </button>
          {/* <button
            className={`delete-button-app ${
              isButtonLoading ? "disabled-area" : ""
            }`}
            onClick={retryFunction}
          >
            {isButtonLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Retying...</span>
              </>
            ) : (
              "Retry"
            )}
          </button> */}
        </div>
      </div>

      {isOpenView && <div className="confirmation-overlay"></div>}
    </>
  );
};

export default TransactionFailedDialog;
