import React, { useEffect, useState } from "react";
import "./favourite-transaction.css";
import NavBar from "../../../../utilities/navbar/NavBar";
import arrow_left from "../../../../assets/arrow-left-line.png";
import { useNavigate } from "react-router-dom";
import Button from "../../../../utilities/button/Button";
import { getBanks, getFavouriteList } from "../../../../services/money_transfer";
import toast from "react-hot-toast";
import { useContextProvider } from "../../../../services/context_provider";

export default function FavouriteTransaction() {
  const {contextData,setContextData} = useContextProvider()
  const navigate = useNavigate();
  const [payeelistLoading, setPayeeListLoading] = useState(false);
  const [favouriteCard, setfavouriteCard] = useState([
  ]);
  function MaskedNumber({ number }:{number:string}) {
    const maskedNumber = number.replace(/\d(?=\d{3})/g, 'x');
    return <div>{maskedNumber}</div>;
  }
  const getFavouritePayees = async () => {
    setPayeeListLoading(true);
    const payeelistRes = await getFavouriteList();
    if (payeelistRes.code === 100) {
      setfavouriteCard(payeelistRes.data)
      setPayeeListLoading(false);
    } else {
      toast.error("Payees getting failed");
      setPayeeListLoading(false);
    }
  };

  const goToChild = (data:any,isFavourite:boolean) => {
    setContextData((prevState: any) => ({
      ...prevState,
      recipeint: data,
    }));
    navigate(`/add-transaction-details`, { state: { dataFavourite: data,isItInFaurite:isFavourite} });
  };
  useEffect(() => {
    getFavouritePayees();
    console.log("Transfer Details:", contextData);
    if (contextData === undefined) {
      navigate(`/transaction-history`, {
        state: { email: localStorage.getItem("userEmail") },
      });
    }
  }, [contextData]);
  return (
    <div className="main-money-transfer-div">
      <NavBar verifiedEmail="" />
      <div className="d-flex justify-content-center align-items-center pt-4">
        <div className="money-transfer-main-div">
          <div className="d-flex justify-content-start align-items-center mb-4 vw-100 arrow-left">
            <img
              src={arrow_left}
              alt=""
              width={24}
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="favourite-topic">Who are you sending for ?</div>
          <div className="favourite-sub-topic mt-3 mb-1">
            Favourite transactions
          </div>
          <div className="scroll-container vw-100">
            {payeelistLoading ? (
              <div className="loader vh-30 vw-100">
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
              </div>
            ) : (
              <>
                {favouriteCard.length !== 0 ? (
                  <>
                    {favouriteCard.map((data:any, index) => (
                      <div key={index} className="favourite-card mt-1" onClick={()=>{goToChild(data,true)}}>
                        <div className="favourite-name">{data?.nick_name}</div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="favourite-details">{data?.recipient_name}</div>
                          <div className="favourite-details">
                          <MaskedNumber number={data?.account_number}/>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="favourite-details">{data?.mobile_number}</div>
                          <div className="favourite-details">
                            {data?.bank?.name}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="no-data-text vh-50 vw-100 d-flex justify-content-center align-items-center">
                    No payees available.
                  </div>
                )}
              </>
            )}
          </div>
          <Button
            label="Add recipient"
            type="submit"
            onClick={()=>{goToChild({},false)}}
            className="continue-button"
            //   loading={passwordSetLoading}
          />
        </div>
      </div>
    </div>
  );
}
