// ToggleButton.tsx
import React, { useState } from 'react';
import './ToggleButton.css';

interface ToggleButtonProps {
  onToggle: (isToggled: boolean) => void;
  initialState?: boolean;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ onToggle, initialState = false }) => {
  const [isToggled, setIsToggled] = useState(initialState);

  const handleToggle = () => {
    const newToggleState = !isToggled;
    setIsToggled(newToggleState);
    onToggle(newToggleState);
  };

  return (
    <div className={`toggle-button ${isToggled ? 'toggled' : ''}`} onClick={handleToggle}>
      <div className="toggle-button-thumb" />
    </div>
  );
};

export default ToggleButton;
