import axios from "axios";
import { base_url } from "../environment/environment";

const API_URL = base_url;
class ApiResponse {
  code: any;
  message: string;
  data?: any;
  constructor(success: any, message: string, data: any = null) {
    this.code = success;
    this.message = message;
    this.data = data;
  }
}


export const getInviteLink = async (): Promise<any> => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
  
    try {
      const response = await axios.get(
        `${API_URL}generate_invite/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return new ApiResponse(100, response.data.message, response.data);
    } catch (error: any) {
      if (error.response?.status === 401) {
        window.location.href = "/login";
      }
      console.error("Error fetching transactions:", error);
      const errorMessage =
        error.response?.data?.message || "An unknown error occurred";
      return new ApiResponse(105, errorMessage);
    }
  };


  export const getReferralPoints = async (): Promise<any> => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
  
    try {
      const response = await axios.get(
        `${API_URL}check_earnings/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return new ApiResponse(100, response.data.message, response.data);
    } catch (error: any) {
      if (error.response?.status === 401) {
        window.location.href = "/login";
      }
      console.error("Error fetching transactions:", error);
      const errorMessage =
        error.response?.data?.message || "An unknown error occurred";
      return new ApiResponse(105, errorMessage);
    }
  };



  export const sendPoints = async (data: any): Promise<any> => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Token not found");
    }
    try {
      const response = await axios.post(`${API_URL}process_rewards_transaction/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      return new ApiResponse(100, response.data.message, response.data);
    } catch (error: any) {
      if (error.response?.status === 401) {
        window.location.href = "/login";
      }
      console.log(error.response.data);
      const errorMessage =
        error.response?.data?.message || "An unknown error occurred";
      return new ApiResponse(105, errorMessage);
    }
  };