import React, { useEffect, useState } from "react";
import NavBar from "../../../../utilities/navbar/NavBar";
import arrow_left from "../../../../assets/arrow-left-line.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../../utilities/button/Button";
import DeleteConfirmationDialog from "../../../../utilities/delete-confirmation-dialog/DeleteConfirmationDialog";
import TransactionFailedDialog from "../../../../utilities/retry-confirmation-dialog/TransactionFailedDialog";
import TransactionSuccessDialog from "../../../../utilities/transaction-success-dialog/TransactionSuccessDialog";
import AddFavouriteDialog from "../../../../utilities/add-favourite-dialog/AddFavouriteDialog";
import { getBanks, proceedPay, sendMoney } from "../../../../services/money_transfer";
import { useContextProvider } from "../../../../services/context_provider";
import toast from "react-hot-toast";
import { sendPoints } from "../../../../services/user_referral";
import CardTokenizationListener from "../../../../configs/firebaseConfigs";
const banks = [
  "Bank of America",
  "Chase Bank",
  "Wells Fargo",
  "Citibank",
  "HSBC",
  // Add more bank names
];
function AddTransactionDetails() {
  const { contextData } = useContextProvider<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const { dataFavourite, isItInFaurite } = location.state || {};
  const [retryTransfer, setRetryTransfer] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [addFavouriteDialog, setAddFavouriteDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredBanks, setFilteredBanks] = useState<any>([]);
  const [listBanks, setListBanks] = useState<any>([]);
  const [transferLoading, setTransferLoading] = useState(false);
  const [bankId, setBankId] = useState(0);
  const [transactionData, setTransacionData] = useState<any>();
  const [isCeftFailed, setIsCeftFailed] = useState(false);
  const [failedReason, setFailedReason] = useState("");
  const [transactionNarration,setTransactionNarration] = useState("")
  const [htmlContent, setHtmlContent] = useState("");
  const [cardTokenizeOrderId, setCardTokenizeOrderId] = useState("");
  const [cardTokenizeOrderId2, setCardTokenizeOrderId2] = useState("");
  const [cardListLoading, setCardListLoading] = useState(false);

  const handleChange = (event: any) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
      const filtered = listBanks.filter((bank: any) =>
        bank?.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredBanks(filtered);
    } else {
      setFilteredBanks([]);
    }
  };
  const getBankDetails = async () => {
    const bankRes = await getBanks();
    if (bankRes.code === 100) {
      setListBanks(bankRes?.data);
    } else {
      toast.error("Bank details getting failed");
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.id = "authenticate-payer-script";
    script.innerHTML = `
      var e = document.getElementById("threedsChallengeRedirectForm");
      if (e) {
        e.submit();
        if (e.parentNode !== null) {
          e.parentNode.removeChild(e);
        }
      }
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [htmlContent]);


  const tokenziedCallBack = async(data: any) => {
    if (data === "SUCCESS") {
      setHtmlContent("");
      console.log("Tokenized callback called :", data);

      let pay_payload={
        order_id:cardTokenizeOrderId2,
        from_currency_code: contextData?.transferData?.from_currency_code,
        to_currency_code: contextData?.transferData?.to_currency_code,
      }
      try{
        const transferRes = await proceedPay(pay_payload);
        console.log(transferRes)
        if(transferRes.code==100){
          if(transferRes.data.transaction_status=="SUCCESS"){

            if(transferRes.data.ceft && transferRes.data.ceft.status=="SUCCESSFUL"){
              let tran_data={
                from_currency_code: contextData?.transferData?.from_currency_code,
                amount: contextData?.transferData?.amount,      }
        
                setTransacionData(tran_data)
                setRetryTransfer(false);
                setSuccessDialog(true);
            }else{
              setHtmlContent("");
              setRetryTransfer(true);
              setIsCeftFailed(true);
              setFailedReason(transferRes.data.transaction_message);
            }
 
          }else{

            setHtmlContent("");
            setRetryTransfer(true);
            setIsCeftFailed(false);
            setFailedReason(transferRes.data.transaction_message);

          }
  
        }else{
          setHtmlContent("");
          setRetryTransfer(true);
          setIsCeftFailed(true);
          setFailedReason("Money transfer failed");
        }

      }catch(e){
console.log(e)
      }


      //toast.success("Money transferred successfully",{duration:6000});
      //navigate("/transaction-history");
    } else if (data === "EMPTY_DOC") {
      console.log("Tokenized callback called :", data);
      //toast.success("Card tokenization failed(doc)");
    } else {
      setHtmlContent("");
      setRetryTransfer(true);
      setIsCeftFailed(true);
      setFailedReason("Money transfer failed");
      console.log("Tokenized callback called :", data);
      //toast.error("Money transfer failed");
    }
  };


  useEffect(() => {
    getBankDetails();
    setSearchQuery(contextData?.recipeint?.bank?.name);
    setBankId(contextData?.recipeint?.bank?.id);
    console.log("Transfer Details:", contextData);
    if (contextData === undefined) {
      navigate(`/transaction-history`, {
        state: { email: localStorage.getItem("userEmail") },
      });
    }
  }, [contextData]);



  const formik = useFormik({
    initialValues: {
      accountName: contextData?.recipeint?.recipient_name || "",
      accountNumber: contextData?.recipeint?.account_number || "",
      contactNumber: contextData?.recipeint?.mobile_number || "",
      bank: contextData?.recipeint?.bank?.code || "",
    },
    validationSchema: Yup.object({
      accountName: Yup.string().required("Account name is required"),
      accountNumber: Yup.string().required("Account number is required"),
      contactNumber: Yup.string().required("Contact number is required").matches(/^\+?[0-9]{10,15}$/, "Contact number must be between 10 to 15 digits, optionally starting with a '+'"),
      bank: Yup.string().required("Bank name is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setTransferLoading(true);
      
      if(contextData?.transferData?.is_points_transfer==false){


      const payload = {
        recipient_name: values.accountName,
        account_number: values.accountNumber,
        bank_code: values.bank,
        mobile_number: values.contactNumber,
        from_currency_code: contextData?.transferData?.from_currency_code,
        to_currency_code: contextData?.transferData?.to_currency_code,
        amount: contextData?.transferData?.amount,
        card_id: contextData?.transferData?.card_id,
        transaction_narration: transactionNarration,
      };

      try {
        const transferRes = await sendMoney(payload);

        if (
          transferRes.code === 100 
        ) {
          formik.resetForm();
          setCardTokenizeOrderId(transferRes.data.session_data.order_id);
          setHtmlContent(transferRes.data.session_data.redirect_html);
          setCardTokenizeOrderId2(transferRes.data.oneid_order_id)
        } else {
          setRetryTransfer(true);
          setIsCeftFailed(false);
          setFailedReason(transferRes?.data?.transaction_message);
        }
      } catch (error) {
        console.error("Error:", error);
      }

    }else{
      const points_payload = {
        recipient_name: values.accountName,
        account_number: values.accountNumber,
        bank_code: values.bank,
        mobile_number: values.contactNumber,
        from_currency_code: contextData?.transferData?.from_currency_code,
        to_currency_code: contextData?.transferData?.to_currency_code,
        amount: contextData?.transferData?.amount,
        transaction_narration: transactionNarration,
      };
     

      try {
        const transferRes = await sendPoints(points_payload);
        if (
          transferRes.code === 100 &&
          transferRes?.data?.transaction_status === "SUCCESS"
        ) {
          if (transferRes?.data?.ceft?.status === "SUCCESSFUL") {
            setTransacionData(transferRes?.data);
            setRetryTransfer(false);
            setSuccessDialog(true);
          } else {
            setRetryTransfer(true);
            setIsCeftFailed(true);
            setFailedReason(transferRes?.data?.ceft?.status_description);
          }
        } else {
          setRetryTransfer(true);
          setIsCeftFailed(false);
          setFailedReason(transferRes?.data?.transaction_message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    },
  });
  const handleNoteTextHandle = (e: any) => {
    console.log(e.target.value);
    setTransactionNarration(e.target.value)
  };

  return (
    <>
    {htmlContent && (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%" }}
      >
        <div
          style={{ width: "100%" }}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
        <CardTokenizationListener
          cardTokenId={cardTokenizeOrderId}
          cardTokenizeCallBack={tokenziedCallBack}
        />
      </div>
    )}

  {!htmlContent &&  <div className="main-money-transfer-div">
      <NavBar verifiedEmail={localStorage.getItem("userEmail")} />
      <div className="d-flex justify-content-center align-items-center pt-4">
        <div className="money-transfer-main-div">
          <div className="d-flex justify-content-start align-items-center mb-4 vw-100 arrow-left">
            <img
              src={arrow_left}
              alt=""
              width={24}
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="favourite-topic">Who are you sending for ?</div>
          <form onSubmit={formik.handleSubmit} className="vw-100">
            <div className="d-flex flex-column justify-content-center align-items-center vw-100">
              <div className="vw-100">
                <div className="email-label-div-text">
                  Recipient account name
                </div>
                <input
                  type="text"
                  className="div-type-input"
                  placeholder="Write Recipient account name"
                  {...formik.getFieldProps("accountName")}
                />
                {formik.touched.accountName && formik.errors.accountName ? (
                  <div className="formik-errors">
                    {typeof formik.errors.accountName === "string"
                      ? formik.errors.accountName
                      : null}
                  </div>
                ) : null}
              </div>
              <div className="vw-100">
                <div className="email-label-div-text">
                  Recipient account number
                </div>
                <input
                  type="text"
                  className="div-type-input"
                  placeholder="Write Recipient account number"
                  {...formik.getFieldProps("accountNumber")}
                  inputMode="numeric"
                  pattern="[0-9]*"
                />
                {formik.touched.accountNumber && formik.errors.accountNumber ? (
                  <div className="formik-errors">
                    {typeof formik.errors.accountNumber === "string"
                      ? formik.errors.accountNumber
                      : null}
                  </div>
                ) : null}
              </div>
              <div className="vw-100">
                <div className="email-label-div-text">
                  Recipient mobile number
                </div>
                <input
                  type="text"
                  className="div-type-input"
                  placeholder="Write Recipient mobile number"
                  {...formik.getFieldProps("contactNumber")}
                />
                {formik.touched.contactNumber && formik.errors.contactNumber ? (
                  <div className="formik-errors">
                    {typeof formik.errors.contactNumber === "string"
                      ? formik.errors.contactNumber
                      : null}
                  </div>
                ) : null}
              </div>
              <div className="vw-100">
                <div className="email-label-div-text">Recipient bank</div>
                <div style={{ position: "relative", width: "100%" }}>
                  <input
                    type="text"
                    placeholder="Search for a bank"
                    value={searchQuery}
                    onChange={handleChange}
                    className="div-type-input"
                  />
                  {filteredBanks.length > 0 && (
                    <ul
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        width: "100%",
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                        maxHeight: "200px",
                        overflowY: "auto",
                        margin: 0,
                        padding: 0,
                        listStyleType: "none",
                        zIndex: 1,
                      }}
                    >
                      {filteredBanks.map((bank: any, index: any) => (
                        <li
                          key={index}
                          style={{
                            padding: "8px",
                            cursor: "pointer",
                            borderBottom: "1px solid #ccc",
                          }}
                          onClick={() => {
                            setSearchQuery(bank.name);
                            setBankId(bank?.id);

                            formik.values.bank = bank.code;
                            setFilteredBanks([]);
                          }}
                        >
                          {bank?.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

                {/* <input
                  type="text"
                  className="div-type-input"
                  placeholder="Write bank"
                  {...formik.getFieldProps("bank")}
                />*/}
                {formik.touched.bank && formik.errors.bank ? (
                  <div className="formik-errors">
                    {typeof formik.errors.bank === "string"
                      ? formik.errors.bank
                      : null}
                  </div>
                ) : null}
              </div>
              <div className="vw-100">
                <div className="label-text">Narration (Optional)
                </div>
                <textarea
                  className="div-type-input-textarea"
                  id="comments"
                  name="comments"
                  rows={4}
                  placeholder="Write a note"
                  value={transactionNarration}
                  onChange={handleNoteTextHandle}
                ></textarea>
              </div>
              <Button
                label="Transfer"
                type="submit"
                onClick={() => {}}
                className="continue-button"
                loading={transferLoading}
              />
            </div>
          </form>
        </div>
      </div>
      {retryTransfer && (
        <TransactionFailedDialog
          failedReason={failedReason}
          isCeftFailed={isCeftFailed}
          onClose={() => {
            setRetryTransfer(false);
          }}
          isButtonLoading={false}
        />
      )}
      {successDialog && (
        <TransactionSuccessDialog
          onClose={() => {
            setSuccessDialog(false);
          }}
          transactionData={transactionData}
          isFavourite={isItInFaurite}
          addFavourite={() => {
            setSuccessDialog(false);
            setAddFavouriteDialog(true);
          }}
        />
      )}
      {addFavouriteDialog && (
        <AddFavouriteDialog
          onClose={() => {
            setAddFavouriteDialog(false);
          }}
          favuriteData={{
            recipient_name: formik.values.accountName,
            account_number: formik.values.accountNumber,
            bank_id: bankId,
            mobile_number: formik.values.contactNumber,
            is_favorite: true,
            nick_name: "",
            action: "Add",
          }}
          isButtonLoading={false}
        />
      )}
    </div>}
    </>
  );
}

export default AddTransactionDetails;
