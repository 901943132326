import axios from "axios";
import { base_url } from "../environment/environment";

const API_URL = base_url;

class ApiResponse {
  code: any;
  message: string;
  data?: any;
  constructor(success: any, message: string, data: any = null) {
    this.code = success;
    this.message = message;
    this.data = data;
  }
}

export const profileDetailsGet = async (
  email: string
): Promise<ApiResponse> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.get(`${API_URL}user/${email}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};

export const profileUpdate = async (data: any, email: string): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.put(`${API_URL}user/update/${email}/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};
export const profileImgUpload = async (data: any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.post(`${API_URL}upload/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};

export const billingAddressAdd = async (data: any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.post(`${API_URL}billing-addresses/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};

export const getBillingAddress = async (
): Promise<ApiResponse> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.get(`${API_URL}billing-addresses/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};

export const shippingAddressAdd = async (data: any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.post(`${API_URL}shipping-addresses/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};

export const getShippingAddress = async (
): Promise<ApiResponse> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.get(`${API_URL}shipping-addresses/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};

export const deleteBillingAddress = async (id: any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.delete(`${API_URL}billing-addresses/${id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};

export const deleteShippingAddress = async (id: any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.delete(`${API_URL}shipping-addresses/${id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};

export const editBillingAddress = async (id: any,data:any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.put(`${API_URL}billing-addresses/${id}/`,data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};
export const editShippingAddress = async (id: any,data:any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.put(`${API_URL}shipping-addresses/${id}/`,data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};
export const setDefaultBillingAddress = async (id: any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.put(`${API_URL}billing-addresses/${id}/set-default/`,"", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};

export const setDefaultShippingAddress = async (id: any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.put(`${API_URL}shipping-addresses/${id}/set-default/`,"", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
}; 

export const passwordReset = async (data: any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.post(`${API_URL}shipping-addresses/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};