// import NodeRSA from "node-rsa";
import forge from 'node-forge';


// Generate RSA key pair (This should be done securely and not on the client-side for real applications)
// const generateKeyPair = () => {
//   const key = new NodeRSA({ b: 512 });
//   const publicKey = key.exportKey("public");
//   const privateKey = key.exportKey("private");
//   return { publicKey, privateKey };
// };

// Encrypt a message with the public key
//const encryptMessage = (message: string) => {
  // const publicKey =
  //   "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA6UT9q3Bbg9Bvnuc+JQDxTdeP7X3dwNHQVBJ271fZ7IhSLuXiccnuEXwe0ly16qlUHCG+RBfibkCyM+d19v9vveECj48os+cQX6LK9mxOhZmhZs64SRDdYDolBR5HV7xTaW5qxxdXX55gp3Ha6HDMDX8ujuXFURaDlFklqPXPCtCcvcwU+oAjUI7tnJJtiuc1a59Lh/pPhCKAys3TY66OsdXJjo5Go1S3QllaK4BuosMpaRp9TDcNIEb4zRhF3gJzovLbfctjrzTPebWRgdsLbPg+nBGTzKU6p+8iOLc7lMccYU5uU7ce4zj8DZBFJV9QjIIYV8bYiickEHxfrM2lvQIDAQAB";

  // const key = new NodeRSA(publicKey);
  // const encrypted = key.encrypt(message, "base64");
  // return encrypted;
  //return 1
//};

const encryptMessage = (data: string) => {

  const publicKeyPem = `
  -----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA6UT9q3Bbg9Bvnuc+JQDx
  TdeP7X3dwNHQVBJ271fZ7IhSLuXiccnuEXwe0ly16qlUHCG+RBfibkCyM+d19v9v
  veECj48os+cQX6LK9mxOhZmhZs64SRDdYDolBR5HV7xTaW5qxxdXX55gp3Ha6HDM
  DX8ujuXFURaDlFklqPXPCtCcvcwU+oAjUI7tnJJtiuc1a59Lh/pPhCKAys3TY66O
  sdXJjo5Go1S3QllaK4BuosMpaRp9TDcNIEb4zRhF3gJzovLbfctjrzTPebWRgdsL
  bPg+nBGTzKU6p+8iOLc7lMccYU5uU7ce4zj8DZBFJV9QjIIYV8bYiickEHxfrM2l
  vQIDAQAB
  -----END PUBLIC KEY-----
  `;
  
  const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
  const encryptedData = publicKey.encrypt(data, 'RSA-OAEP');

  // Convert the encrypted data to Base64
  const encryptedBase64 = forge.util.encode64(encryptedData);
  return encryptedBase64;
};

// Decrypt a message with the private key
const decryptMessage = (encryptedMessage: string, private_Key: string) => {
  const privateKey = forge.pki.privateKeyFromPem(private_Key);

    // Decode the Base64 encoded encrypted data
    const encryptedBytes = forge.util.decode64(encryptedMessage);

    // Decrypt the data
    const decryptedData = privateKey.decrypt(encryptedBytes, 'RSA-OAEP');
    return decryptedData;
};

export { encryptMessage, decryptMessage };
