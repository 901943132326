import axios from "axios";
import { base_url } from "../environment/environment";

const API_URL = base_url;
class ApiResponse {
  code: any;
  message: string;
  data?: any;
  constructor(success: any, message: string, data: any = null) {
    this.code = success;
    this.message = message;
    this.data = data;
  }
}

export const getTransactions = async (
  email: string,
  offset: any
): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }

  try {
    const response = await axios.get(
      `${API_URL}customer/transactions/${email}/?limit=10&offset=${offset}&ordering=-request_datetime`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.error("Error fetching transactions:", error);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};

export const getRemitTransactions = async (offset: any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }

  try {
    const response = await axios.get(
      `${API_URL}remit-transactions-history/?page=${offset}&page_size=10`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.error("Error fetching transactions:", error);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};
export const downloadRemitTransactionPdf = async (data: any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.get(
      `${API_URL}pdf-receipt/?transaction_id=${data?.transaction_id}&status=${data.mpgs_transaction_status}&amount=${data.from_amount}&send_amount=${data.receiver_amount}&card_fees=${data.card_fees}&instant_transfer_fee=${data.instant_transfer_fee}&total_fees=${data.card_fees+data.instant_transfer_fee}&exchange_rate=${data.exchange_rate}&payment_method=${data.card_brand}&recipient_account_name=${data.recipient_name}&recipient_account_number=${data.recipient_account_number}&recipient_mobile_number=${data.recipient_mobile_number}&recipient_bank=${data.recipient_bank_name}&transfer_description=${data.transaction_narration === ""?"-":data.transaction_narration}`,
      
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};
export const downloadIpgTransactionPdf = async (data: any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.get(
      `${API_URL}ipg-pdf-receipt/?transaction_id=${data?.onepay_transaction_id}&payment_date=2024-09-30&amount=5000.00&merchant_name=MerchantX&brand_name=BrandX&merchant_email=merchant@example.com&customer_name=John%20Doe&customer_contact=0772772779`,
      
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response;
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};
