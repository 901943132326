// import React, { useRef, useState } from "react";
// import "./OTPInput.css";

// interface OTPInputProps {
//   length: number; // Number of OTP digits
//   onChange: (otp: string) => void; // Callback function when OTP changes
// }

// const OTPInput: React.FC<OTPInputProps> = ({ length, onChange }) => {
//   const [otpValues, setOTPValues] = useState<string[]>(
//     new Array(length).fill("")
//   );
//   const inputRefs = useRef<(HTMLInputElement | null)[]>(
//     new Array(length).fill(null)
//   );

//   // Handle input change in each OTP input field
//   const handleChange = (
//     e: React.ChangeEvent<HTMLInputElement>,
//     index: number
//   ) => {
//     const value = e.target.value;
//     if (isNaN(Number(value))) {
//       return; // Accept only numeric values
//     }

//     const newOTPValues = [...otpValues];
//     newOTPValues[index] = value.charAt(value.length - 1); // Allow only single digit

//     setOTPValues(newOTPValues);
//     const otp = newOTPValues.join("");
//     onChange(otp); // Notify parent component of OTP change

//     // Move focus to the next input field
//     if (value !== "" && index < length - 1) {
//       inputRefs.current[index + 1]?.focus();
//     }
//   };

//   // Handle backspace/delete to move focus to previous input field
//   const handleKeyDown = (
//     e: React.KeyboardEvent<HTMLInputElement>,
//     index: number
//   ) => {
//     if (e.key === "Backspace" || e.key === "Delete") {
//       const newOTPValues = [...otpValues];
//       newOTPValues[index] = "";
//       setOTPValues(newOTPValues);

//       if (index > 0) {
//         inputRefs.current[index - 1]?.focus();
//       }
//     }
//   };

//   return (
//     <div className="otp-input">
//       {Array.from({ length }).map((_, index) => (
//         <input
//           key={index}
//           type="text"
//           maxLength={1}
//           value={otpValues[index]}
//           onChange={(e) => handleChange(e, index)}
//           onKeyDown={(e) => handleKeyDown(e, index)}
//           ref={(el) => (inputRefs.current[index] = el)}
//           className="otp-input-field"
//           inputMode="numeric"
//           pattern="[0-9]*"
//         />
//       ))}
//     </div>
//   );
// };

// export default OTPInput;
import React, { useRef, useState, forwardRef, useImperativeHandle } from "react";
import "./OTPInput.css";

interface OTPInputProps {
  length: number; // Number of OTP digits
  onChange: (otp: string) => void; // Callback function when OTP changes
}

const OTPInput = forwardRef(({ length, onChange }: OTPInputProps, ref) => {
  const [otpValues, setOTPValues] = useState<string[]>(
    new Array(length).fill("")
  );
  const inputRefs = useRef<(HTMLInputElement | null)[]>(
    new Array(length).fill(null)
  );

  // Expose reset method to parent component
  useImperativeHandle(ref, () => ({
    reset() {
      setOTPValues(new Array(length).fill(""));
      inputRefs.current[0]?.focus();
    },
  }));

  // Handle input change in each OTP input field
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    if (isNaN(Number(value))) {
      return; // Accept only numeric values
    }

    const newOTPValues = [...otpValues];
    newOTPValues[index] = value.charAt(value.length - 1); // Allow only single digit

    setOTPValues(newOTPValues);
    const otp = newOTPValues.join("");
    onChange(otp); // Notify parent component of OTP change

    // Move focus to the next input field
    if (value !== "" && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  // Handle backspace/delete to move focus to previous input field
  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      const newOTPValues = [...otpValues];
      newOTPValues[index] = "";
      setOTPValues(newOTPValues);

      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };
    // Handle pasting the OTP
    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pasteData = e.clipboardData.getData("text").slice(0, length);
      if (!/^\d+$/.test(pasteData)) {
        return; // Ensure only numeric values are pasted
      }
  
      const newOTPValues = pasteData.split("").slice(0, length);
  
      setOTPValues(newOTPValues);
      onChange(newOTPValues.join("")); // Notify parent component of OTP change
  
      // Move focus to the last filled input field
      inputRefs.current[newOTPValues.length - 1]?.focus();
    };

  return (
    <div className="otp-input">
      {Array.from({ length }).map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength={1}
          value={otpValues[index]}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          ref={(el) => (inputRefs.current[index] = el)}
          className="otp-input-field"
          inputMode="numeric"
          pattern="[0-9]*"
        />
      ))}
    </div>
  );
});

export default OTPInput;
