import React, { useEffect, useState } from "react";
import './DeleteConfirmationDialog.css';
import delete_popup_icon from "../../assets/delete_popup_icon.png";

interface ConfirmationDialogProps {
  deleteItemName: string;
  deleteFunction: () => void;
  onClose: () => void;
  isButtonLoading?: boolean;
}

const DeleteConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  deleteItemName,
  deleteFunction,
  onClose,
  isButtonLoading
}) => {
  const [isOpenView, setIsOpenView] = useState(false);

  useEffect(() => {
    // Trigger the transition effect
    setTimeout(() => setIsOpenView(true), 0);
  }, []);

  const handleClose = () => {
    setIsOpenView(false);
    setTimeout(onClose, 500); // Match the transition duration
  };

  return (
    <>
      <div className={`confirmation-dialog-app ${isOpenView ? 'show' : ''}`}>
        <div className="row justify-content-center">
          <img className="pe-2 feature-icon-update" src={delete_popup_icon} />
        </div>
        <div className="d-flex justify-content-center topic-app">
          Are you sure you want to delete <br /> {deleteItemName}?
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ fontSize: "14px" }}
        >
          If you delete your {deleteItemName}, you will no longer <br /> be able
          to use or configure it.
        </div>
        <div className="d-flex justify-content-center buttons-container-app">
          <button className="button-cancel-app" onClick={handleClose}>
            Cancel
          </button>
          <button
            className={`delete-button-app ${
              isButtonLoading ? "disabled-area" : ""
            }`}
            onClick={deleteFunction}
          >
            {isButtonLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Deleting...</span>
              </>
            ) : (
              "Delete"
            )}
          </button>
        </div>
      </div>

      {isOpenView && <div className="confirmation-overlay"></div>}
    </>
  );
};

export default DeleteConfirmationDialog;
