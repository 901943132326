import axios from "axios";
import { base_url } from "../environment/environment";

const API_URL = base_url;
class ApiResponse {
  code: any;
  message: string;
  data?: any;
  constructor(success: any, message: string, data: any = null) {
    this.code = success;
    this.message = message;
    this.data = data;
  }
}

export const addPayee = async (data: any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.post(`${API_URL}payees/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};

export const getFavouriteList = async (): Promise<ApiResponse> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.get(`${API_URL}payees/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};

export const getCurrencyConvert = async (
  amount: any,
  from_currency: any,
  to_currency: any
): Promise<ApiResponse> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.get(
      `${API_URL}currency/convert/?amount=${amount}&from_currency=${from_currency}&to_currency=${to_currency}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return new ApiResponse(100, response?.data?.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};

export const sendMoney = async (data: any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.post(`${API_URL}remit-transaction-ds/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};

export const getBanks = async (
): Promise<ApiResponse> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.get(
      `${API_URL}banks/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response.data);
    return new ApiResponse(100, response?.data?.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};


export const proceedPay = async (data: any): Promise<any> => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Token not found");
  }
  try {
    const response = await axios.post(`${API_URL}commi-transaction-ds/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return new ApiResponse(100, response.data.message, response.data);
  } catch (error: any) {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    console.log(error.response.data);
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    return new ApiResponse(105, errorMessage);
  }
};