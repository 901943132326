import React, { useState } from "react";
import NavBar from "../../../utilities/navbar/NavBar";
import arrow_left from "../../../assets/Frame 5524.png";
import "./PointTransfer.css";
import Button from "../../../utilities/button/Button";
import { useContextProvider } from "../../../services/context_provider";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

interface PointTransferProps{
  onClose:()=>void,
  availablePoints:number,
}
const PointTransfer = ({ onClose,availablePoints }:PointTransferProps) => {
  const { contextData, setContextData } = useContextProvider<any>();
  const navigate  = useNavigate()

  const [moneyTrnsferData, setMoneyTransferData] = useState({
    card_id:
      contextData?.transferData !== undefined
        ? contextData?.transferData?.card_id
        : "",
    from_currency_code:
      contextData?.transferData?.from_currency_code !== undefined
        ? contextData?.transferData?.from_currency_code
        : "USD",
    to_currency_code:
      contextData?.transferData?.to_currency_code !== undefined
        ? contextData?.transferData?.to_currency_code
        : "LKR",
    amount:
      contextData?.transferData?.amount !== undefined
        ? contextData?.transferData?.amount
        : 10,
    converted_amount: "",
    transaction_narration:
      contextData?.transferData?.transaction_narration !== undefined
        ? contextData?.transferData?.transaction_narration
        : "",
        is_points_transfer: true
  });

  
  const goToChild = () => {

    if (moneyTrnsferData?.amount > availablePoints) {
      toast.error("Your tranfer points exceeds available points");
      return;
    }

    if (moneyTrnsferData?.amount === "0") {
      toast.error("Transfer points can't be zero");
      return;
    }

    if (moneyTrnsferData?.amount < 3000 ) {
      toast.error("Transfer points should be 3000 or above");
      return;
    }

    setContextData((prevState: any) => ({
      ...prevState,
      transferData: moneyTrnsferData,
    }));
    navigate(`/set-favourite`);
  };

  const handleSendAmountHandle = (e: any) => {
    console.log(e.target.value);
    setMoneyTransferData((prevState) => ({
      ...prevState,
      amount: e.target.value, // Replace "new-card-id" with the actual value you want to set
    }));
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-5">
      <div className="d-flex justify-content-start align-items-center div-width mb-4">
        <img
          src={arrow_left}
          alt=""
          width={24}
          style={{ cursor: "pointer", marginRight: "20px" }}
          onClick={()=>{
            onClose()
          }}
        />
        <div>
          <div className="point-topic">One ID Reward point</div>
          <div className="point-sub-topic">Invite friends and get rewards</div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="point-amount-text mb-3">Enter point amount</div>
          <input
                placeholder="00.00"
                className="points-value"
                type="number"
                style={{
                  border: "none",
                  width: "auto",
                  outline: "none",
                  textAlign:"center"
                }}
                value={moneyTrnsferData?.amount}
                onChange={handleSendAmountHandle}
                inputMode="decimal"
                pattern="[0-9]*[.,]?[0-9]*"
              />
          {/* <div className="points-value">00.00</div> */}
          <div className="points-text">points</div>
        </div>
      </div>
      <div className="div-border mt-4 mb-3"></div>
      <div className="div-width">
        <div className="d-flex justify-content-start align-items-center vw-100">
            <div className="vw-70 curency-rate">$1</div>
            <div className="curency-rate">= 300 points</div>
        </div>
        <div className="d-flex justify-content-start align-items-center vw-100">
            <div className="vw-70 curency-rate">LKR 1</div>
            <div className="curency-rate">= 1 point</div>
        </div>
      </div>
      <div className="div-border mt-4 mb-3"></div>
      <div className="div-width">
        <div className="d-flex justify-content-start align-items-center vw-100">
            <div className="vw-70 point-sub-topic">Remaining balance</div>
            <div className="curency-rate">= 0 points</div>
        </div>
      </div>
      <div className="div-width mt-2">
      <Button
                label="Transfer"
                type="submit"
                onClick={() => {goToChild()}}
                className="transfer-button"
                loading={false}
              />
      </div>
    </div>
  );
};

export default PointTransfer;
